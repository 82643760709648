import React from "react";
import { DefaultInput, DefaultSelect, DefaultTextarea } from "templates/input";

export default function Form({
  id,
  handleSubmit,
  loading,
  inputsData,
  handleUserChange,
  handleChange,
  membershipHandleChange,
  getDefaultValue,
  error,
  user,

  use_int_admin,
  use_text_name,
  use_text_lastname,
  use_text_user,
  use_text_phone,
  use_text_address,

  mem_data_from,
  mem_data_until,
  mem_int_value_hasto,
  mem_int_value_commitment,
  mem_text_obs,
  mem_int_frequency,
  typ_int_id,
}) {
  return (
    <form
      onSubmit={handleSubmit}
      className="row mx-0 w-100 pt-3 align-items-start"
      autoComplete="off"
    >
      {id === "0" && (
        <>
          <h4 className="text-start">Datos del usuario</h4>

          {/* <div className="mb-3 d-flex flex-column">
            <label className="form-label">Usuario</label>
            <input
              list="users"
              name="use_int_id"
              className="form-control"
              placeholder="Seleccione un usuario"
              onChange={handleUserChange}
              defaultValue={use_int_id ? getDefaultValue(use_int_id) : ""}
              disabled={use_int_id ? true : false}
            />
            <datalist id="users">
              {inputsData.users.map((item) => (
                <option
                  value={`[${item.use_int_id}] - ${item.use_text_name} ${item.use_text_lastname}`}
                  key={item.use_int_id}
                />
              ))}
            </datalist>
          </div> */}

          <DefaultInput
            customClass="col-12 col-lg-6"
            label="Nombres"
            placeholder="Ingresa los nombres"
            inputName="use_text_name"
            value={user ? user.use_text_name : use_text_name}
            disabled={user ? true : false}
            error={error.use_text_name}
            onChange={membershipHandleChange}
          />
          <DefaultInput
            customClass="col-12 col-lg-6"
            label="Apellidos"
            placeholder="Ingresa los apellidos"
            inputName="use_text_lastname"
            value={user ? user.use_text_lastname : use_text_lastname}
            disabled={user ? true : false}
            error={error.use_text_lastname}
            onChange={membershipHandleChange}
          />
          <DefaultInput
            customClass="col-12 col-lg-6"
            label="Correo electrónico"
            placeholder="Ingresa un correo"
            inputName="use_text_user"
            value={user ? user.use_text_user : use_text_user}
            disabled={user ? true : false}
            error={error.use_text_user}
            onChange={membershipHandleChange}
          />
          <DefaultInput
            customClass="col-12 col-lg-6"
            label="Teléfono"
            placeholder="Ingresa un teléfono"
            inputName="use_text_phone"
            value={user ? user.use_text_phone : use_text_phone}
            disabled={user ? true : false}
            error={error.use_text_phone}
            onChange={membershipHandleChange}
          />
          <DefaultInput
            customClass="col-12"
            label="Dirección"
            placeholder="Ingresa una dirección"
            inputName="use_text_address"
            value={user ? user.use_text_address : use_text_address}
            disabled={user ? true : false}
            error={error.use_text_address}
            onChange={membershipHandleChange}
          />
        </>
      )}

      {id === "0" && <h4 className="text-start mt-4">Datos de la membresía</h4>}
      <DefaultSelect
        customClass={id === "0" ? "col-12 col-lg-6" : "col-12"}
        label="Tipo de membresía"
        defaultValue={typ_int_id}
        inputName="typ_int_id"
        placeholder={!typ_int_id ? "Selecciona una opción" : null}
        options={inputsData.types?.map((item) => ({
          value: item.typ_int_id,
          name: `${item.typ_text_name} - $ ${item.typ_int_value}`,
        }))}
        onChange={membershipHandleChange}
        error={error.typ_int_id}
      />

      <DefaultInput
        customClass="col-12 col-lg-6"
        label="Desde"
        type="date"
        inputName="mem_data_from"
        value={mem_data_from?.split(" ")[0]}
        error={error.mem_data_from}
        onChange={membershipHandleChange}
        disabled={id !== "0" ? true : false}
      />

      {id !== "0" && (
        <DefaultInput
          customClass="col-12 col-lg-6"
          label="Hasta"
          type="date"
          inputName="mem_data_until"
          value={mem_data_until?.split(" ")[0]}
          error={error.mem_data_until}
          onChange={membershipHandleChange}
        />
      )}

      {/* <DefaultInput
        customClass="col-12 col-lg-6"
        label="Valor membresía"
        placeholder="Ingrese un valor"
        value={mem_int_value_hasto}
        inputName="mem_int_value_hasto"
        onChange={membershipHandleChange}
        error={error.mem_int_value_hasto}
      /> */}

      <DefaultInput
        customClass="col-12 col-lg-6"
        label="Valor compromiso"
        placeholder="Ingrese un valor"
        value={mem_int_value_commitment || ""}
        inputName="mem_int_value_commitment"
        onChange={membershipHandleChange}
        error={error.mem_int_value_commitment}
      />

      <DefaultSelect
        customClass="col-12 col-lg-6"
        label="Frecuencia"
        defaultValue={mem_int_frequency || "1"}
        inputName="mem_int_frequency"
        placeholder={!mem_int_frequency ? "Selecciona una opción" : null}
        options={[
          { value: "1", name: "Mensual" },
          { value: "3", name: "Trimestral" },
          { value: "6", name: "Semestral" },
          { value: "12", name: "Anual" },
        ]}
        onChange={membershipHandleChange}
        error={error.mem_int_frequency}
      />

      <DefaultTextarea
        customClass="col-12"
        label="Observaciones"
        placeholder="Ingresa tus observaciones"
        value={mem_text_obs}
        inputName="mem_text_obs"
        onChange={membershipHandleChange}
        error={error.mem_text_obs}
        required={false}
      />

      <div className="col-12 d-flex justify-content-end">
        <button
          type="submit"
          className={`btn btn-${loading ? "secondary" : "primary"}`}
          disabled={loading ? true : false}
        >
          {loading ? (
            <>
              <i className="fas fa-circle-notch fa-spin me-2"></i>
              Guardando...
            </>
          ) : (
            <>
              <i className="fas fa-save me-2"></i>
              Guardar
            </>
          )}
        </button>
      </div>
    </form>
  );
}
