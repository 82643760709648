import React, { useEffect, useRef } from "react";

export default function TabsButtons({ handleTab }) {
  const tabOneRef = useRef();

  useEffect(() => {
    tabOneRef.current.click();
  }, []);

  //prettier-ignore
  return (
    <div className="btn-group mb-0 flex-shrink-1 mb-3 mb-md-0" role="group" aria-label="Selección de secciones">
      <input onClick={handleTab} type="radio" className="btn-check" name="btnradio" id="tab-1" autoComplete="off" value='1' ref={tabOneRef} />
      <label className="btn btn-outline-secondary btn-sm" htmlFor="tab-1"><i className="fas fa-list me-lg-2"></i> Seguimiento</label>

      <input onClick={handleTab} type="radio" className="btn-check" name="btnradio" id="tab-2" autoComplete="off" value='2' />
      <label className="btn btn-outline-secondary btn-sm" htmlFor="tab-2"><i className="fas fa-address-book me-lg-2"></i> Contacto</label>

      <input onClick={handleTab} type="radio" className="btn-check" name="btnradio" id="tab-3" autoComplete="off" value='3' />
      <label className="btn btn-outline-secondary btn-sm" htmlFor="tab-3"><i className="fas fa-history me-lg-2"></i> Historial</label>
    </div>
  )
}
