import React, { useEffect } from "react";

import { DefaultInput, DefaultTextarea } from "templates/input";

export default function ExpensesForm({
  id,
  handleSubmit,
  handleChange,
  loading,
  setData,
  error,
  exp_text_reference,
  exp_text_observations,
  exp_int_type,
  exp_int_value,
}) {
  useEffect(() => {
    setData((prevData) => ({ ...prevData, exp_int_type: "0" }));
    //eslint-disable-next-line
  }, []);

  return (
    <div className="w-100">
      <h3 className="text-center">
        {id !== "0" ? "Editar egreso" : "Nuevo egreso"}
      </h3>
      <form
        onSubmit={handleSubmit}
        className="w-100 row mx-0"
        autoComplete="off"
      >
        <DefaultInput
          customClass="col-12"
          label="Referencia"
          placeholder="Ingresa una referencia"
          value={exp_text_reference}
          inputName="exp_text_reference"
          onChange={handleChange}
          error={error.exp_text_reference}
        />

        <DefaultTextarea
          customClass="col-12"
          label="Observaciones"
          placeholder="Ingresa tus observaciones"
          value={exp_text_observations}
          inputName="exp_text_observations"
          onChange={handleChange}
          error={error.exp_text_observations}
          required={false}
        />

        <DefaultInput
          customClass="col-12 "
          label="Valor"
          placeholder="Ingresa un valor"
          value={exp_int_value}
          inputName="exp_int_value"
          onChange={handleChange}
          error={error.exp_int_value}
          required={false}
        />

        <div className="col-12 d-flex justify-content-between">
          <h5 className="text-center fw-bold">
            Valor a pagar: {exp_int_value || "0"}$
          </h5>

          <button
            type="submit"
            className={`btn btn-${loading ? "secondary" : "primary"}`}
            disabled={loading ? true : false}
          >
            {loading ? (
              <>
                <i className="fas fa-circle-notch fa-spin me-2"></i>
                Guardando...
              </>
            ) : (
              <>
                <i className="fas fa-save me-2"></i>
                Guardar
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
