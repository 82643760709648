import React, { useEffect, useState } from "react";
import useRequest from "hooks/useRequest";
import { useParams } from "react-router";

import Form from "components/MembersFormComponents/Form";
import Info from "components/MembersFormComponents/Info";
import History from "components/MembersFormComponents/History";

export default function MemberForm({
  id,
  handleSubmit,
  handleChange,
  setData,
  loading,
  error,
  info,
  pagos,
  membresias,
}) {
  const { reqTokenBase } = useRequest();
  const { userId } = useParams();

  const [step, setStep] = useState(1);
  const [user, setUser] = useState(null);
  const [inputsData, setInputsData] = useState({
    types: [],
    users: [],
  });

  const getData = async () => {
    const types = reqTokenBase.get("memberships/types?l=999&o=0");
    const users = reqTokenBase.get("administration/users?l=999&o=0");

    const res = await Promise.all([types, users]);

    if (userId) {
      const userData = await reqTokenBase.get(`administration/users/${userId}`);
      setUser(userData.data);
    }

    setInputsData({
      types: res[0].data.datos,
      users: res[1].data.datos,
    });
  };

  const handleUserChange = ({ target }) => {
    const id = target.value.split(" ")[0].replaceAll(/[^\w\s]/gi, "");

    const user = inputsData.users.find((item) => item.use_int_id === id);

    if (user) {
      return setData((prevData) => ({
        ...prevData,
        info: { ...prevData?.info, use_int_id: id },
      }));
    }

    setData((prevData) => ({
      ...prevData,
      info: { ...prevData?.info, use_int_id: "" },
    }));
  };

  const membershipHandleChange = ({ target }) => {
    if (
      (target.name === "mem_data_from" || target.name === "mem_data_until") &&
      id === "0"
    ) {
      return setData((prevData) => ({
        ...prevData,
        info: { ...prevData?.info, [target.name]: target.value + " 00:00:00" },
      }));
    }

    setData((prevData) => ({
      ...prevData,
      info: { ...prevData?.info, [target.name]: target.value },
    }));
  };

  // const membershipHandleChange = ({target}) => {
  //   setData((prevData) => ({...prevData, membresias: {...prevData.info, [target.name]: target.value}}))
  // }

  const getDefaultValue = (id) => {
    const user = inputsData.users.find((item) => item.use_int_id === id);

    if (user) {
      return `${user.use_text_name} ${user.use_text_lastname}`;
    }

    return "";
  };

  const handleStep = ({ target }) => {
    setStep(Number(target.value));
  };

  const getMembershipValue = () => {
    const value = inputsData.types.find(
      (item) => item.typ_int_id === info.typ_int_id
    );
    setData((prevData) => ({
      ...prevData,
      info: {
        ...prevData?.info,
        mem_int_value_commitment: value?.typ_int_value || "",
      },
    }));
  };

  useEffect(() => {
    getData();

    if (id === "0") {
      setData((prevData) => ({ ...prevData, mem_int_frequency: "1" }));
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getMembershipValue();

    //eslint-disable-next-line
  }, [info?.typ_int_id]);

  if (id !== "0") {
    return (
      <div className="d-flex flex-column align-items-center w-100">
        <div
          className="btn-group mb-3"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btn1"
            autocomplete="off"
            checked={step === 1 ? true : false}
            value={1}
            onChange={handleStep}
          />
          <label className="btn btn-outline-secondary" for="btn1">
            Editar miembro
          </label>

          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btn2"
            autocomplete="off"
            checked={step === 2 ? true : false}
            value={2}
            onChange={handleStep}
          />
          <label className="btn btn-outline-secondary" for="btn2">
            Membresías y pagos
          </label>

          {id !== "0" && (
            <>
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="bt3"
                autocomplete="off"
                checked={step === 3 ? true : false}
                value={3}
                onChange={handleStep}
              />
              <label className="btn btn-outline-secondary" for="bt3">
                Información del usuario [ Solo lectura ]
              </label>
            </>
          )}
        </div>

        {step === 1 && (
          <Form
            id={id}
            handleSubmit={handleSubmit}
            loading={loading}
            inputsData={inputsData}
            handleUserChange={handleUserChange}
            handleChange={handleChange}
            membershipHandleChange={membershipHandleChange}
            getDefaultValue={getDefaultValue}
            error={error}
            {...info}
            {...membresias[0]}
          />
        )}

        {step === 2 && <History pagos={pagos} membresias={membresias} />}

        {step === 3 && <Info {...info} />}
      </div>
    );
  }

  return (
    <div className="d-flex flex-column align-items-center">
      {!userId ? (
        <h2>Nuevo miembro</h2>
      ) : (
        <h2>
          Nueva membresía para {user?.use_text_name} {user?.use_text_lastname}
        </h2>
      )}
      <Form
        id={id}
        handleSubmit={handleSubmit}
        loading={loading}
        inputsData={inputsData}
        handleUserChange={handleUserChange}
        handleChange={handleChange}
        membershipHandleChange={membershipHandleChange}
        getDefaultValue={getDefaultValue}
        error={error}
        user={user}
        {...info}
      />
    </div>
  );
}
