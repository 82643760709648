import { useState } from "react";

export default function useRefresh() {
  // toggle modal state
  const [refreshModal, setRefreshModal] = useState(false);

  // renew token countdown state
  const [autoCloseSession, setAutoCloseSession] = useState(false);

  let refreshTimeout;
  let autoCloseSessionTimeout;

  const closeSessionCountdownStart = () => {
    autoCloseSessionTimeout = setTimeout(() => {
      setAutoCloseSession(true);

      setRefreshModal(false);
      setAutoCloseSession(false);
      // }, 30000);
    }, 60000);
  };

  const refreshTokenCountdownStart = () => {
    // setRefreshModal(false);

    refreshTimeout = setTimeout(() => {
      // open renew modal
      setRefreshModal(true);

      // start autoclose countdown
      closeSessionCountdownStart();
      // }, 10000);
    }, 3300000);
  };

  const cancelRefresh = () => window.clearTimeout(refreshTimeout);
  const cancelAutoClose = () => window.clearTimeout(autoCloseSessionTimeout);

  return {
    // restart state to show modal
    setRefreshModal,
    // if modal should show
    refreshModal,
    // if session should close
    autoCloseSession,
    // refresh timeout
    refreshTokenCountdownStart,
    // clear timeouts functions
    cancelRefresh,
    cancelAutoClose,
  };
}
