import React from "react";

import { DefaultInput } from "templates/input";

export default function TypesForm({
  id,
  handleSubmit,
  handleChange,
  loading,
  error,
  typ_text_name,
  typ_int_value,
}) {
  return (
    <div className="w-100">
      <h3 className="text-center">
        {id !== "0" ? "Editar tipo" : "Nuevo tipo"}
      </h3>
      <form onSubmit={handleSubmit} className="w-100" autoComplete="off">
        <DefaultInput
          customClass="col-12"
          label="Nombre"
          placeholder="Ingresa un nombre"
          value={typ_text_name}
          inputName="typ_text_name"
          onChange={handleChange}
          error={error.typ_text_name}
        />

        <DefaultInput
          customClass="col-12"
          label="Valor"
          placeholder="Ingresa un valor"
          value={typ_int_value}
          inputName="typ_int_value"
          onChange={handleChange}
          error={error.typ_int_value}
        />

        <div className="col-12 d-flex justify-content-end">
          <button
            type="submit"
            className={`btn btn-${loading ? "secondary" : "primary"}`}
            disabled={loading ? true : false}
          >
            {loading ? (
              <>
                <i className="fas fa-circle-notch fa-spin me-2"></i>
                Guardando...
              </>
            ) : (
              <>
                <i className="fas fa-save me-2"></i>
                Guardar
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
