import React from "react";

import TextEditor from "components/TextEditor";
import BusinessList from "components/BusinessList/BusinessList";
import ScrollContainer from "components/ScrollContainer";

import "styles/Contact.css";

export default function Contact({
  tab,
  issue,
  contact,
  customerId,
  id,
  setFieldsInfo,
}) {
  const handleCopy = ({ target }) => {
    navigator.clipboard.writeText(target.childNodes[1].innerText);
  };

  return (
    <div className={`w-100 flex-wrap gap-3 ${tab === 2 ? "d-flex" : "d-none"}`}>
      <div className="w-100 d-flex flex-column flex-lg-row gap-3">
        <div className="d-flex flex-column border rounded custom-width">
          <p className="w-100 py-2 px-3 text-start bg-success rounded-top text-white fw-bold mb-0">
            Información del contacto
          </p>
          <div className="row mx-0 w-100 p-2">
            <div
              onClick={handleCopy}
              title="Click para copiar"
              className="copy-container col-12 col-md-6 d-flex flex-column align-items-center justify-content-start border py-2"
            >
              <span className="fw-bold w-100">Nombres</span>
              <p className="mb-0 word-break w-100">{contact.cus_text_name}</p>
            </div>
            <div
              onClick={handleCopy}
              title="Click para copiar"
              className="copy-container col-12 col-md-6 d-flex flex-column align-items-center justify-content-start border py-2"
            >
              <span className="fw-bold w-100">Apellidos</span>
              <p className="mb-0 word-break w-100">
                {contact.cus_text_lastname}
              </p>
            </div>
            <div
              onClick={handleCopy}
              title="Click para copiar"
              className="copy-container col-12 col-md-6 d-flex flex-column align-items-center justify-content-start border py-2"
            >
              <span className="fw-bold w-100">Celular</span>
              <p className="mb-0 word-break w-100">{contact.cus_text_phone}</p>
            </div>
            <div
              onClick={handleCopy}
              title="Click para copiar"
              className="copy-container col-12 col-md-6 d-flex flex-column align-items-center justify-content-start border py-2"
            >
              <span className="fw-bold w-100">Correo</span>
              <p className="mb-0 word-break w-100">{contact.cus_text_email}</p>
            </div>
            <div
              onClick={handleCopy}
              title="Click para copiar"
              className="copy-container col-12 col-md-6 d-flex flex-column align-items-center justify-content-start border py-2"
            >
              <span className="fw-bold w-100">Dirección</span>
              <p className="mb-0 word-break w-100">
                {contact.cus_text_address}
              </p>
            </div>
            <div
              onClick={handleCopy}
              title="Click para copiar"
              className="copy-container col-12 col-md-6 d-flex flex-column align-items-center justify-content-start border py-2"
            >
              <span className="fw-bold w-100">Fecha de creación</span>
              <p className="mb-0 word-break w-100">
                {new Date(contact.cus_date_creation_date).toLocaleString()}
              </p>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column border rounded custom-width">
          <BusinessList
            url="customers"
            relationId={customerId}
            height={180}
            relationField="cus_int_id"
            checkedField="bxcchecked"
          />
        </div>
      </div>

      <div className="d-flex flex-column border rounded w-100">
        <p className="w-100 py-2 px-3 text-start bg-warning rounded-top text-dark fw-bold mb-0">
          Observaciones
        </p>
        <div className="w-100 p-2">
          <TextEditor
            onChange={(value) =>
              setFieldsInfo((prevData) => ({
                ...prevData,
                remarks: value,
              }))
            }
            defaultContent={issue.issue_data.cus_text_obs ?? ""}
          />
        </div>
      </div>

      <div className="d-flex flex-column border rounded w-100">
        <p className="w-100 py-2 px-3 text-start bg-dark rounded-top text-white fw-bold mb-0">
          Archivos adjuntos
        </p>
        <div className="w-100 p-2 pt-3">
          <ScrollContainer
            url={`administration/files_customers/${id}`}
            deleteUrl="files_customers"
            uploadUrl="files_customers"
            headers={<Headers />}
            type="customer_file"
            noAdd
            upload
            formDistance="4"
            id={id}
          />
        </div>
      </div>
    </div>
  );
}

const Headers = () => {
  return (
    <div className="row w-100 mx-0 px-0 bg-secondary rounded mb-3">
      <span className="col-3 col-md-1 fw-bold border-end py-2 text-center text-white">
        ID
      </span>
      <span className="col-9 col-md-9 fw-bold border-end py-2 text-white">
        Archivo
      </span>
      <span className="d-none d-md-block col-4 col-md-2 fw-bold text-center py-2 text-white">
        Opciones
      </span>
    </div>
  );
};
