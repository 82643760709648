import React from "react";

import { DefaultTextarea } from "templates/input";
import AccessList from "components/AccessList/AccessList";

export default function GroupsForm({
  id,
  handleSubmit,
  handleChange,
  loading,
  error,
  gro_text_description,
}) {
  return (
    <div className="row w-100 g-3">
      <h3 className="text-center">
        {id !== "0" ? "Editar grupo" : "Nuevo grupo"}
      </h3>
      <form
        onSubmit={handleSubmit}
        className={`${
          id !== "0" ? "col-12 col-lg-6" : "col-12"
        } align-items-center`}
        autoComplete="off"
      >
        <DefaultTextarea
          customClass="col-12"
          label="Descripción"
          placeholder="Ingresa una descripción"
          value={gro_text_description}
          inputName="gro_text_description"
          onChange={handleChange}
          error={error.gro_text_description}
        />

        <div className="col-12 d-flex justify-content-end">
          <button
            type="submit"
            className={`btn btn-${loading ? "secondary" : "primary"}`}
            disabled={loading ? true : false}
          >
            {loading ? (
              <>
                <i className="fas fa-circle-notch fa-spin me-2"></i>
                Guardando...
              </>
            ) : (
              <>
                <i className="fas fa-save me-2"></i>
                Guardar
              </>
            )}
          </button>
        </div>
      </form>

      {id !== "0" && (
        <div className="col-12 col-lg-6">
          <AccessList
            url="groups"
            relationId={id}
            height={210}
            relationField="gro_int_id"
          />
        </div>
      )}
    </div>
  );
}
