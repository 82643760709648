/*

  ----- Explicación -----

  - Para que sea más fácil listar todas las rutas esta este archivo, que será listado por la navegación lateral
  y solo requerirá que se modifique aquí las rutas haciendolo más fácil de usar.

  - Cada grupo o "[]" dentro de RouterData creada un grupo separado y marcado dentro de la navegación lateral.
  - Dentro de cada grupo, para crear un link o ruta, basta con crear un objeto con su ruta en la key "link" y su
  nombre en "name".
  -Para crear una lista de rutas, se creará un objeto con el key "link", solo el nombre de la lista, y en la pro-
  piedad "childrens" se listara dentro de un "[]" las rutas de la misma forma que antes.

*/

const RouterData = [
  {
    id: "1",
    link: "/home",
    name: "Inicio",
    icon: "fas fa-home",
  },
  {
    id: "2",
    link: "/customers",
    name: "Contactos",
    icon: "fas fa-users",
  },
  {
    id: "3",
    link: "/calendar",
    name: "Agenda",
    icon: "fas fa-calendar-alt",
  },
  {
    id: "4",
    link: "/management",
    name: "Panel",
    icon: "fas fa-desktop",
  },
  {
    id: "5",
    link: "/memberships",
    name: "Membresías",
    icon: "fas fa-id-card",
  },
  {
    id: "6",
    link: "/reports",
    name: "Reportes",
    icon: "fas fa-chart-line",
  },
];

export default RouterData;
