import React from "react";

import ScrollContainer from "components/ScrollContainer";

export default function Home() {
  return (
    <div className="w-100 h-100 d-flex flex-column align-items-center card p-3 p-xl-5 bg-light">
      <h3 className="text-center mb-5">Lista de contactos</h3>

      <ScrollContainer
        url="administration/customers"
        headers={<Headers />}
        type="customers"
      />
    </div>
  );
}

const Headers = () => {
  return (
    <div className="row w-100 mx-0 px-0 bg-secondary rounded mb-3">
      <span className="d-none d-md-block col-md-1 fw-bold border-end py-2 text-center text-white">
        ID
      </span>
      <span className="col-7 col-md-9 fw-bold border-end py-2 text-white">
        Descripción
      </span>
      <span className="col-5 col-md-2 fw-bold text-center py-2 text-white">
        Opciones
      </span>
    </div>
  );
};
