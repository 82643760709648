import React from "react";

import { DefaultCheckbox, DefaultInput } from "templates/input";

import BusinessList from "components/BusinessList/BusinessList";
import GroupsList from "components/GroupsList/GroupsList";
import AccessList from "components/AccessList/AccessList";

export default function UsersForm({
  id,
  handleSubmit,
  handleChange,
  setData,
  loading,
  error,
  use_text_name,
  use_text_lastname,
  use_text_user,
  use_text_phone,
  use_text_address,
  use_text_pass,
  use_int_admin,
}) {
  const setAdmin = () => {
    setData((prevData) => ({ ...prevData, use_int_admin: "1" }));
  };

  const setUser = () => {
    setData((prevData) => ({ ...prevData, use_int_admin: "0" }));
  };

  return (
    <div className="d-flex flex-column">
      <h3 className="text-center">
        {id !== "0" ? "Editar usuario" : "Nuevo usuario"}
      </h3>
      <form
        onSubmit={handleSubmit}
        className="row mx-0 w-100 pt-3 align-items-end"
        autoComplete="off"
      >
        <DefaultInput
          customClass="col-12 col-lg-6"
          label="Nombres"
          placeholder="Ingresa los nombres"
          value={use_text_name}
          inputName="use_text_name"
          onChange={handleChange}
          error={error.use_text_name}
        />
        <DefaultInput
          customClass="col-12 col-lg-6"
          label="Apellidos"
          placeholder="Ingresa los apellidos"
          value={use_text_lastname}
          inputName="use_text_lastname"
          onChange={handleChange}
          error={error.use_text_lastname}
        />
        <DefaultInput
          customClass="col-12 col-lg-6"
          label="Correo electrónico"
          type="email"
          placeholder="Ingresa un correo electrónico"
          value={use_text_user}
          inputName="use_text_user"
          onChange={handleChange}
          error={error.use_text_user}
        />
        <DefaultInput
          customClass="col-12 col-lg-6"
          label="Teléfono"
          type="tel"
          inputMode="numeric"
          placeholder="Ingresa un número de teléfono"
          value={use_text_phone}
          inputName="use_text_phone"
          onChange={handleChange}
          error={error.use_text_phone}
        />
        <DefaultInput
          customClass="col-12 col-lg-6"
          label="Dirección"
          placeholder="Ingresa una dirección"
          value={use_text_address}
          inputName="use_text_address"
          onChange={handleChange}
          error={error.use_text_address}
        />
        <DefaultInput
          customClass="col-12 col-lg-6"
          label="Contraseña (Si no la ingresa, se generará una y se le enviará por correo)"
          type="password"
          placeholder="Ingresa una contraseña"
          value={use_text_pass}
          inputName="use_text_pass"
          onChange={handleChange}
          error={error.use_text_pass}
        />

        <div className="col-12 d-flex justify-content-center flex-wrap border mb-4 p-3 ">
          <p
            className="text-center col-12 mt-2 mb-4 fw-bold"
            style={{ letterSpacing: 1 }}
          >
            Tipo de usuario
          </p>

          <DefaultCheckbox
            customClass="me-5"
            label="Administrador"
            value={use_int_admin === "1" && true}
            inputName="use_int_admin"
            onChange={setAdmin}
          />

          <DefaultCheckbox
            customClass=""
            label="Usuario"
            value={use_int_admin === "0" && true}
            inputName="use_int_admin"
            onChange={setUser}
          />
        </div>

        <div className="col-12 d-flex justify-content-end">
          <button
            type="submit"
            className={`btn btn-${loading ? "secondary" : "primary"}`}
            disabled={loading ? true : false}
          >
            {loading ? (
              <>
                <i className="fas fa-circle-notch fa-spin me-2"></i>
                Guardando...
              </>
            ) : (
              <>
                <i className="fas fa-save me-2"></i>
                Guardar
              </>
            )}
          </button>
        </div>
      </form>

      {id !== "0" && (
        <>
          <hr />

          <div className="row mx-0 w-100 g-3 justify-content-center">
            <div className="col-12 col-lg-6">
              <BusinessList
                url="users"
                relationId={id}
                height={210}
                relationField="use_int_id"
                checkedField="businesschecked"
              />
            </div>

            <div className="col-12 col-lg-6">
              <GroupsList
                url="users"
                relationId={id}
                height={210}
                relationField="use_int_id"
              />
            </div>

            <div className="col-12">
              <AccessList
                url="users"
                relationId={id}
                height={210}
                relationField="use_int_id"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
