import axios from "axios";
import qs from "qs";

import useAuth from "hooks/useAuth";

const BASE_URL = process.env.REACT_APP_ADMINISTRATION_BASE_URL;
const BASE_BASE_URL = process.env.REACT_APP_BASE_BASE_URL;

let userSecret = process.env.REACT_APP_OAUTH2_CLIENT;
let passSecret = process.env.REACT_APP_OAUTH2_SECRET;

const base64 = btoa(userSecret + ":" + passSecret);

export default function useRequest() {
  const auth = useAuth();

  const reqLogin = axios.create({
    baseURL: BASE_BASE_URL,
    headers: {
      Authorization: `Basic ${base64}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    transformRequest: [(data) => qs.stringify(data)],
  });

  const reqToken = axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${
        auth?.user?.access_token || sessionStorage.getItem("access_token")
      }`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    transformRequest: [(data) => qs.stringify(data)],
  });

  const reqTokenFormData = axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${
        auth?.user?.access_token || sessionStorage.getItem("access_token")
      }`,
      "Content-Type": "application/form-data",
    },
    // transformRequest: [(data) => qs.stringify(data)],
  });

  const reqTokenFormDataBase = axios.create({
    baseURL: BASE_BASE_URL,
    headers: {
      Authorization: `Bearer ${
        auth?.user?.access_token || sessionStorage.getItem("access_token")
      }`,
      "Content-Type": "application/form-data",
    },
    // transformRequest: [(data) => qs.stringify(data)],
  });

  const reqTokenBase = axios.create({
    baseURL: BASE_BASE_URL,
    headers: {
      Authorization: `Bearer ${
        auth?.user?.access_token || sessionStorage.getItem("access_token")
      }`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    transformRequest: [(data) => qs.stringify(data)],
  });

  const reqUpload = axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${
        auth?.user?.access_token || sessionStorage.getItem("access_token")
      }`,
      "Content-Type": "multipart/form-data",
    },
  });

  const reqDownload = axios.create({
    baseURL: BASE_URL,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${
        auth?.user?.access_token || sessionStorage.getItem("access_token")
      }`,
      "Content-Type": "multipart/form-data",
    },
  });

  return {
    reqLogin,
    reqToken,
    reqTokenFormData,
    reqTokenFormDataBase,
    reqTokenBase,
    reqUpload,
    reqDownload,
  };
}
