import { createContext, useEffect, useRef } from "react";
import useLogin from "hooks/useLogin";

import CloseToExpirationModal from "./CloseToExpirationModal";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const {
    loading: loginLoading,
    user,
    login,
    logout,
    isLogged,
    refreshToken,
    refreshModal,
    autoCloseSession,
  } = useLogin();

  const toggleExpirationModalRef = useRef();

  useEffect(() => {
    if (refreshModal) toggleExpirationModalRef.current.click();
  }, [refreshModal]);

  useEffect(() => {
    // sessionStorage.clear();
    refreshToken();
    //eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{ loginLoading, user, login, logout, isLogged }}
    >
      <button
        ref={toggleExpirationModalRef}
        type="button"
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#CloseToExpiration"
      >
        asd
      </button>

      {refreshModal && (
        <CloseToExpirationModal
          refresh={refreshToken}
          logout={logout}
          loading={loginLoading}
          autoCloseSession={autoCloseSession}
        />
      )}

      {children}
    </AuthContext.Provider>
  );
};
