import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import useRequest from "hooks/useRequest";

import { DefaultInput, DefaultSelect, DefaultTextarea } from "templates/input";
import PaymentsHistory from "components/PaymentsComponents/PaymentsHistory";

export default function PaymentsForm({
  id,
  handleSubmit,
  handleChange,
  loading,
  error,
  setData,
  mem_int_id,
  pay_int_value,
  pay_int_type,
  pay_text_reference,
  pay_text_observations,
}) {
  const { reqTokenBase } = useRequest();
  const { userId } = useParams();

  const [membersList, setMembersList] = useState([]);
  const [paymentsData, setPaymentsData] = useState([]);

  const getData = async () => {
    const membersList = await reqTokenBase.get("memberships/members?l=999&o=0");
    setMembersList(membersList.data.datos);
  };

  const getPayments = async () => {
    const user = membersList.find((item) => item.mem_int_id === userId);

    if (user) {
      const res = await reqTokenBase.get(
        `memberships/status/${user.use_int_id}`
      );
      setPaymentsData(res.data.datos_membresias);
    }
  };

  const handleUserChange = ({ target }) => {
    const id = target.value.split(" ")[0].replaceAll(/[^\w\s]/gi, "");

    const user = membersList.find((item) => item.mem_int_id === id);

    if (user) {
      return setData((prevData) => ({
        ...prevData,
        mem_int_id: user.mem_int_id,
      }));
    }

    setData((prevData) => ({
      ...prevData,
      mem_int_id: "",
    }));
  };

  const getDefaultValue = (id) => {
    const user = membersList.find((item) => item.mem_int_id === id);

    if (user) {
      return user.use_text_names;
    }

    return "";
  };

  const handleFileChange = async ({ target }) => {
    if (target.files.length === 0) {
      return;
    }

    setData((prevData) => ({
      ...prevData,
      file: target.files[0],
      filename: target.files[0].name.replaceAll(" ", "-"),
    }));
  };

  useEffect(() => {
    getData();

    if (id === "0") {
      setData((prevData) => ({ ...prevData, pay_int_type: "1" }));
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userId) {
      setData((prevData) => ({ ...prevData, mem_int_id: userId }));
      getPayments();
    }

    //eslint-disable-next-line
  }, [membersList]);

  return (
    <div className="d-flex flex-column">
      <h3 className="text-center">
        {id !== "0" ? "Editar pago" : "Nuevo pago"}
      </h3>
      <form
        onSubmit={handleSubmit}
        className="row mx-0 w-100 pt-3 align-items-start"
        autoComplete="off"
      >
        <div className="mb-3 d-flex flex-column">
          <label className="form-label">Usuario</label>
          <input
            list="users"
            name="mem_int_id"
            className="form-control"
            placeholder="Seleccione un usuario"
            onChange={handleUserChange}
            defaultValue={
              mem_int_id || userId ? getDefaultValue(mem_int_id || userId) : ""
            }
            disabled={mem_int_id || userId ? true : false}
          />
          <datalist id="users">
            {membersList.map((item) => (
              <option
                value={`[${item.mem_int_id}] - ${item.use_text_names}`}
                key={item.mem_int_id}
              />
            ))}
          </datalist>
          {error.mem_int_id && (
            <p className="text-danger mb-0 mt-1">{error.mem_int_id}</p>
          )}
        </div>

        <DefaultInput
          customClass="col-12 col-lg-6"
          label="Valor"
          placeholder="Ingresa un valor"
          value={pay_int_value}
          inputName="pay_int_value"
          onChange={handleChange}
          error={error.pay_int_value}
        />

        <DefaultSelect
          customClass="col-12 col-lg-6"
          label="Tipo"
          defaultValue={pay_int_type}
          inputName="pay_int_type"
          placeholder={!pay_int_type ? "Selecciona una opción" : null}
          options={[
            { value: "1", name: "Membresía" },
            { value: "2", name: "Donación" },
            { value: "3", name: "Bienes" },
            { value: "4", name: "Servicios" },
            { value: "5", name: "Curso" },
          ]}
          onChange={handleChange}
          error={error.pay_int_type}
        />

        <DefaultTextarea
          customClass="col-12 col-lg-6"
          label="Referencia"
          placeholder="Ingresa una referencia"
          value={pay_text_reference}
          inputName="pay_text_reference"
          onChange={handleChange}
          error={error.pay_text_reference}
        />

        <DefaultTextarea
          customClass="col-12 col-lg-6"
          label="Observaciones"
          placeholder="Ingresa tus observaciones"
          value={pay_text_observations}
          inputName="pay_text_observations"
          onChange={handleChange}
          error={error.pay_text_observations}
          required={false}
        />

        <DefaultInput
          customClass="col-12"
          label="Adjuntar archivo"
          type="file"
          onChange={handleFileChange}
          error={error.file}
          required={false}
        />

        <div className="col-12 d-flex justify-content-between">
          <h5 className="text-center fw-bold">
            Valor a pagar: {pay_int_value || "0"}$
          </h5>

          <button
            type="submit"
            className={`btn btn-${loading ? "secondary" : "primary"}`}
            disabled={loading ? true : false}
          >
            {loading ? (
              <>
                <i className="fas fa-circle-notch fa-spin me-2"></i>
                Guardando...
              </>
            ) : (
              <>
                <i className="fas fa-save me-2"></i>
                Guardar
              </>
            )}
          </button>
        </div>
      </form>

      {userId && <PaymentsHistory data={paymentsData} />}
    </div>
  );
}
