import React from "react";

import "styles/History.css";

export default function History({ tab, issue }) {
  return (
    <div className={`w-100 flex-wrap gap-3 ${tab === 3 ? "d-flex" : "d-none"}`}>
      <div className="d-flex flex-column border rounded w-100">
        <p className="w-100 py-2 px-3 text-start bg-primary rounded-top text-white fw-bold mb-0">
          Historial de eventos
        </p>
        <div className="w-100 p-2">
          <Headers />

          <div className="history-container">
            {issue.followsData === 0 && (
              <div className="alert alert-warning text-center p-3 mt-4">
                No hay historial
              </div>
            )}

            {issue.followsData !== 0 &&
              issue.followsData.map((item) => (
                <HistoryItem
                  key={item.fol_int_id}
                  desc={item.fol_text_description}
                  lastContact={item.fol_date_creation_date}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const Headers = () => {
  return (
    <div className="row w-100 mx-0 px-0 bg-secondary rounded-top">
      <span className="col-7 col-md-9 fw-bold border-end py-2 text-start text-white">
        Descripción
      </span>
      <span className="col-5 col-md-3 fw-bold border-end py-2 text-white text-center">
        Último contacto
      </span>
      {/* <span className="d-none d-md-block col-md-2 fw-bold text-center py-2 text-white">
        Opciones
      </span> */}
    </div>
  );
};

const HistoryItem = ({ desc, lastContact }) => {
  return (
    <div className="history-item row w-100 mx-0 px-0 rounded-bottom border align-items-center">
      <p className="col-7 col-md-9 border-end py-2 text-justify mb-0 word-break">
        {desc.replace(/<[^>]+>/g, "")}
      </p>
      <span className="col-5 col-md-3  py-2 h-100 text-center">
        {new Date(lastContact).toLocaleString()}
      </span>
      {/* <span className="d-none d-md-flex col-md-2 py-2 gap-2 justify-content-center">
        <button
          // onClick={handleRedirect}
          type="button"
          className="btn btn-secondary btn-sm"
          // disabled={erreasing ? true : false}
        >
          <i className="far fa-edit"></i>
        </button>

        <button
          type="button"
          className="btn btn-danger btn-sm"
          // disabled={erreasing ? true : false}
          data-bs-toggle="modal"
          // data-bs-target={hashCustomDeleteId}
        >
          <i className="far fa-trash-alt"></i>
        </button>
      </span> */}
    </div>
  );
};
