import React, { useEffect, useRef, useState } from "react";

import useRequest from "hooks/useRequest";

export default function UploadButton({ id, handleReload, uploadEndpoint }) {
  const { reqUpload } = useRequest();
  const fileInputRef = useRef();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);

  const btnClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async ({ target }) => {
    const reducer = (acc, val) => acc + val.size;
    const files = Array.from(target.files);
    const allowedFiles = files.every(
      (file) => file.type.substr(0, 11) === "application"
    );
    const filesSize = Number((files.reduce(reducer, 0) / 1048576).toFixed(2));

    if (target.files.length === 0) {
      // setFile(null);
      return;
    }

    if (!allowedFiles) {
      setError("Archivo no permitido");
      return;
    }

    if (filesSize > 10) {
      setError("Máximo 10 MB");
      return;
    }

    setLoading(true);

    const config = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);

        if (percent < 100) {
          setPercent(percent);
        }
      },
    };

    const data = new FormData();
    data.append("file", target.files[0]);
    data.append("id", id);

    await reqUpload.post(uploadEndpoint, data, config);

    setLoading(false);

    handleReload();
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error]);

  return (
    <div className="d-flex">
      <button
        onClick={btnClick}
        type="button"
        className={`btn btn-${!error ? "warning" : "danger"}`}
        disabled={loading ? true : false}
      >
        {!loading && (
          <>
            <i className="fas fa-upload me-2"></i>
            Subir
          </>
        )}

        {loading && (
          <>
            <i className="fas fa-circle-notch fa-spin me-2"></i>
            {percent}% subido
          </>
        )}

        {error && (
          <>
            <i className="fas fa-ban me-2"></i>
            {error}
          </>
        )}
      </button>

      <div className="d-none">
        <input
          onChange={handleFileChange}
          ref={fileInputRef}
          className="form-control"
          type="file"
        />
      </div>
    </div>
  );
}
