import React from "react";

import useRequest from "hooks/useRequest";

import DeleteModal from "components/ListsComponents/DeleteModal";

export default function IssueFileListItem({
  item,
  id,
  title,
  deleteItem,
  erreasing,
  module,
}) {
  const { reqDownload } = useRequest();
  const customDeleteId = "file-list-item-delete-" + item.isu_int_id;
  const hashCustomDeleteId = "#" + customDeleteId.replaceAll(" ", "");

  const deleteFunction = () => {
    deleteItem(id);
  };

  const handleDownload = async () => {
    const res = await reqDownload.get(`files/${id}?modulo=${module}`);

    const href = window.URL.createObjectURL(res.data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", title); // name with extension
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="row w-100 mx-0 px-0 border-bottom align-items-center">
      <span className="col-3 col-md-1 border-end py-2 text-center">{id}</span>

      <span className="col-9 col-md-9 border-end py-2 text-truncate">
        {title}
      </span>

      <div className="d-none d-md-flex col-4 col-md-2 py-2 justify-content-center gap-3">
        <button
          onClick={handleDownload}
          type="button"
          className="btn btn-primary btn-sm"
          disabled={erreasing ? true : false}
        >
          <i className="fas fa-download"></i>
        </button>

        <button
          type="button"
          className="btn btn-danger btn-sm"
          disabled={erreasing ? true : false}
          data-bs-toggle="modal"
          data-bs-target={hashCustomDeleteId}
        >
          <i className="far fa-trash-alt"></i>
        </button>
      </div>

      <DeleteModal
        customId={customDeleteId}
        title={title}
        id={id}
        deleteFunction={deleteFunction}
        erreasing={erreasing}
      />
    </div>
  );
}
