import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import useRequest from "hooks/useRequest";

import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";

export default function Calendar() {
  const history = useHistory();
  const { reqToken } = useRequest();

  const [events, setEvents] = useState([]);

  const getData = async () => {
    const res = await reqToken.get("vwlistfollowusers");

    setEvents(
      res.data.map((item) => ({
        title: item.fol_text_description,
        start: item.fol_date_creation_date,
        end: item.fol_date_deletion_date,
        id: item.isu_int_id,
      }))
    );
  };

  const handleSelect = (selectInfo) => {
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection
    history.push(`/newissue/${selectInfo.startStr}`);
  };

  const handleEventClick = ({ event }) => {
    history.push(`/issues/${event.id}`);
  };

  useEffect(() => {
    getData();

    //eslint-disable-next-line
  }, []);

  return (
    <div className="w-100 h-100 d-flex flex-column align-items-center card p-3 p-xl-5 bg-light gap-3">
      <div className="w-100">
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          selectable={true}
          headerToolbar={{
            left: "prevYear,prev,next,nextYear today",
            center: "",
            right: "title",
          }}
          select={handleSelect}
          events={events}
          eventClick={handleEventClick}
          locales={[esLocale]}
          locale="es"
        />
      </div>
    </div>
  );
}
