import { useEffect } from "react";

import AppRouter from "router/AppRouter";

function App() {
  useEffect(() => {
    if (localStorage.getItem("darkSwitch")) {
      document.body.setAttribute("data-theme", "dark");
      localStorage.setItem("darkSwitch", "dark");
    } else {
      document.body.removeAttribute("data-theme");
      localStorage.removeItem("darkSwitch");
    }
  }, []);

  return <AppRouter />;
}

export default App;
