import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import useRequest from "hooks/useRequest";

import TabsButtons from "components/Issue/TabsButtons";
import OptionsButtons from "components/Issue/OptionsButtons";

import Tracking from "components/Issue/Tracking";
import Contact from "components/Issue/Contact";
import History from "components/Issue/History";

import "styles/Issue.css";

export default function Issue() {
  const { reqToken } = useRequest();
  const { id } = useParams();
  const history = useHistory();

  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const [issue, setIssue] = useState({});
  const [topics, setTopics] = useState([]);

  // state that save data for save and tracking update
  const [fieldsInfo, setFieldsInfo] = useState({
    description: "",
    commitment: "",
    topic_id: "",
    next_date_string: "",
    next_date_number: "",
    remarks: "",
    customer_id: "",
  });

  // handle tab change to show each component
  const handleTab = ({ target }) => {
    setTab(Number(target.value));
  };

  // get issue and topics data
  const getData = async () => {
    try {
      setLoading(true);

      const issuePromise = reqToken.get(`issues/${id}`);
      const topicsPromise = reqToken.get("topics?l=999&o=0");

      const response = await Promise.all([issuePromise, topicsPromise]);

      const issue = response[0].data;
      const topics = response[1].data.datos;

      setIssue(issue);
      setTopics(topics);

      // set default fields data
      setFieldsInfo({
        description: issue.issue_data.isu_text_description || "",
        commitment: issue.firstFollowData.fol_text_description || "",
        topic_id: issue.issue_data.top_int_id || "",
        next_date_string: issue.firstFollowData.fol_date_to?.split(" ")[0],
        next_date_number: issue.firstFollowData.fol_date_to
          ? issue.firstFollowData.fol_date_to
              .split(" ")[0]
              .split("-")
              .map((item) => Number(item))
          : "",
        remarks: issue.issue_data.cus_text_obs || "",
        customer_id: issue.customerData.cus_int_id,
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  const saveIssue = async ({ noRedirect = false }) => {
    try {
      // save desc, topic and customer remarks
      await reqToken.put(`issues/${issue.issue_data.isu_int_id}`, {
        // isu_text_description: fieldsInfo.description,
        top_int_id: fieldsInfo.topic_id,
        cus_int_id: fieldsInfo.customer_id,
        cus_text_obs: fieldsInfo.remarks,
      });

      // save next date and commitment text
      if (fieldsInfo.next_date_string) {
        await reqToken.post("follow", {
          isu_int_id: issue.issue_data.isu_int_id,
          fol_date_from: new Date().toLocaleDateString(),
          fol_date_to: `${
            fieldsInfo.next_date_string
          } ${new Date().toLocaleTimeString()}`,
          fol_text_description: fieldsInfo.commitment,
        });
      }

      if (!noRedirect) {
        history.push("/home");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   saveIssue({ noRedirect: true });

  //   //eslint-disable-next-line
  // }, [tab]);

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="w-100 shadow border d-flex flex-column align-items-center justify-content-center rounded">
      {loading && (
        <div className="w-100 bg-light">
          <div className="d-flex justify-content-center py-5">
            <div
              className="spinner-border"
              role="status"
              style={{ width: "2rem", height: "2rem" }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}

      {!loading && (
        <>
          <div className="w-100 d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between bg-dark text-white px-3 py-2 gap-3">
            <h5 className="text-center text-lg-start w-100">
              #{issue.issue_data.isu_int_id} {issue.customerData.cus_text_name}{" "}
              {issue.customerData.cus_text_lastname}
            </h5>

            <TabsButtons handleTab={handleTab} />

            <OptionsButtons saveIssue={saveIssue} />
          </div>

          <div className="w-100 p-3">
            <Tracking
              tab={tab}
              issue={issue}
              topics={topics}
              id={issue?.issue_data.isu_int_id}
              fieldsInfo={fieldsInfo}
              setFieldsInfo={setFieldsInfo}
            />
            <Contact
              tab={tab}
              issue={issue}
              contact={issue.customerData}
              customerId={issue.customerData.cus_int_id}
              id={issue?.issue_data.cus_int_id}
              setFieldsInfo={setFieldsInfo}
            />

            <History tab={tab} issue={issue} />
          </div>
        </>
      )}
    </div>
  );
}
