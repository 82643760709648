import React from "react";
import { DefaultCheckbox, DefaultInput } from "templates/input";

export default function Info({
  use_text_name,
  use_text_lastname,
  use_text_user,
  use_text_phone,
  use_text_address,
  use_int_admin,
}) {
  return (
    <div className="w-100">
      <div className="row mx-0 w-100 pt-3 align-items-end">
        <DefaultInput
          disabled
          customClass="col-12 col-lg-6"
          label="Nombres"
          placeholder="Ingresa los nombres"
          value={use_text_name}
          inputName="use_text_name"
        />
        <DefaultInput
          disabled
          customClass="col-12 col-lg-6"
          label="Apellidos"
          placeholder="Ingresa los apellidos"
          value={use_text_lastname}
          inputName="use_text_lastname"
        />
        <DefaultInput
          disabled
          customClass="col-12 col-lg-6"
          label="Correo electrónico"
          type="email"
          placeholder="Ingresa un correo electrónico"
          value={use_text_user}
          inputName="use_text_user"
        />
        <DefaultInput
          disabled
          customClass="col-12 col-lg-6"
          label="Teléfono"
          type="tel"
          inputMode="numeric"
          placeholder="Ingresa un número de teléfono"
          value={use_text_phone}
          inputName="use_text_phone"
        />
        <DefaultInput
          disabled
          customClass="col-12"
          label="Dirección"
          placeholder="Ingresa una dirección"
          value={use_text_address}
          inputName="use_text_address"
        />

        <div className="col-12 d-flex justify-content-center flex-wrap border mb-4 p-3 ">
          <p
            className="text-center col-12 mt-2 mb-4 fw-bold"
            style={{ letterSpacing: 1 }}
          >
            Tipo de usuario
          </p>

          <DefaultCheckbox
            customClass="me-5"
            label="Administrador"
            value={use_int_admin === "1" && true}
            inputName="use_int_admin"
            onChange={() => {}}
          />

          <DefaultCheckbox
            customClass=""
            label="Usuario"
            value={use_int_admin === "0" && true}
            inputName="use_int_admin"
            onChange={() => {}}
          />
        </div>
      </div>
    </div>
  );
}
