import React from "react";

import "../../styles/MemberHistory.css";

export default function History({ pagos, membresias }) {
  return (
    <div className="w-100 mt-3">
      <div className="row mx-0 g-3">
        <div className="col-12 col-lg-6 d-flex flex-column">
          <h4 className="text-center">Historial de pagos</h4>
          <div className="border rounded mt-2 d-flex flex-column p-2 p-lg-3 custom-history-scroll">
            <div
              className="accordion accordion bg-transparent"
              id="accordionPayments"
            >
              {pagos.map((item, index) => (
                <PaymentItem item={item} index={index} key={index} />
              ))}
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6 d-flex flex-column">
          <h4 className="text-center">Historial de membresías</h4>
          <div className="border rounded mt-2 d-flex flex-column p-2 p-lg-3 custom-history-scroll">
            <div
              className="accordion accordion bg-transparent"
              id="accordionMemberships"
            >
              {membresias.map((item, index) => (
                <MembershipItem item={item} index={index} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const PaymentItem = ({ item, index }) => {
  return (
    <div className="accordion-item bg-transparent border">
      <h2 className="accordion-header" id={`item-${index}`}>
        <button
          className="btn w-100 d-flex justify-content-between align-items-center text-dark"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collpase-${index}`}
          aria-expanded="false"
          aria-controls={`collpase-${index}`}
        >
          {item.pay_text_reference}
          <i className="fas fa-eye"></i>
        </button>
      </h2>
      <div
        id={`collpase-${index}`}
        className="accordion-collapse collapse"
        aria-labelledby={`item-${index}`}
        data-bs-parent="#accordionPayments"
      >
        <div className="accordion-body">
          <p className="w-100 mb-1">
            <strong className="me-2">Fecha:</strong>
            {new Date(item.pay_date_creation_date).toLocaleDateString()}
          </p>
          <p className="w-100 mb-1">
            <strong className="me-2">Referencia:</strong>
            {item.pay_text_reference}
          </p>
          <p className="w-100 mb-1">
            <strong className="me-2">Valor:</strong>
            {item.pay_int_value}
          </p>
          <p className="w-100 mb-0">
            <strong className="me-2">Observaciones:</strong>
            {item.pay_text_observations}
          </p>
        </div>
      </div>
    </div>
  );
};

const MembershipItem = ({ item, index }) => {
  return (
    <div className="accordion-item bg-transparent border">
      <h2 className="accordion-header" id={`mem-${index}`}>
        <button
          className="btn w-100 d-flex justify-content-between align-items-center text-dark"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collpase-mem-${index}`}
          aria-expanded="false"
          aria-controls={`collpase-mem-${index}`}
        >
          {item.mem_data_from
            ? new Date(item.mem_data_from).toLocaleDateString()
            : "Fecha inicio"}
          -
          {item.mem_data_until
            ? new Date(item.mem_data_until).toLocaleDateString()
            : "Fecha fin"}
          <i className="fas fa-eye"></i>
        </button>
      </h2>
      <div
        id={`collpase-mem-${index}`}
        className="accordion-collapse collapse"
        aria-labelledby={`mem-${index}`}
        data-bs-parent="#accordionMemberships"
      >
        <div className="accordion-body">
          <p className="w-100 mb-1">
            <strong className="me-2">Fecha inicio:</strong>
            {item.mem_data_from
              ? new Date(item.mem_data_from).toLocaleDateString()
              : ""}
          </p>
          <p className="w-100 mb-1">
            <strong className="me-2">Fecha final:</strong>
            {item.mem_data_until
              ? new Date(item.mem_data_until).toLocaleDateString()
              : ""}
          </p>
          <p className="w-100 mb-1">
            <strong className="me-2">Valor membresía:</strong>
            {item.mem_int_value_hasto || ""}
          </p>
          <p className="w-100 mb-0">
            <strong className="me-2">Valor compromiso:</strong>
            {item.mem_int_value_commitment || ""}
          </p>
        </div>
      </div>
    </div>
  );
};
