import React from "react";
import { Link, NavLink } from "react-router-dom";

import useAuth from "hooks/useAuth";
import RouterData from "router/RouterData";

import logo from "images/logo-navbar.png";

export default function Navbar() {
  const auth = useAuth();
  const isDark = localStorage.getItem("darkSwitch");

  const resetTheme = ({ target }) => {
    if (target.checked) {
      document.body.setAttribute("data-theme", "dark");
      localStorage.setItem("darkSwitch", "dark");
    } else {
      document.body.removeAttribute("data-theme");
      localStorage.removeItem("darkSwitch");
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid d-flex justify-content-between">
        <Link className="navbar-brand" to="/home">
          <img src={logo} alt="Logo" width="100" />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars text-white fa-md"></i>
        </button>

        <div className="collapse navbar-collapse flex-grow-0" id="navbarNav">
          <ul className="navbar-nav gap-3">
            {RouterData.map((item) => (
              <li className="nav-item" key={item.id}>
                <NavLink
                  className="nav-link"
                  activeClassName="nav-link-active"
                  aria-current="page"
                  to={item.link}
                >
                  <i className={`${item.icon} me-2`}></i>
                  {item.name}
                </NavLink>
              </li>
            ))}

            <li className="nav-item d-block d-lg-none">
              <button
                onClick={auth.logout}
                className="btn btn-danger w-100 btn-sm"
              >
                Salir
                <i className="fas fa-sign-out-alt ms-2"></i>
              </button>
            </li>
          </ul>
        </div>

        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="darkSwitch"
            defaultChecked={isDark ? true : false}
            onChange={resetTheme}
          />
          <label className="form-check-label text-white" htmlFor="darkSwitch">
            Modo obscuro
          </label>
        </div>

        <button
          onClick={auth.logout}
          className="d-none d-lg-block btn btn-danger btn-sm"
        >
          Salir
          <i className="fas fa-sign-out-alt ms-2"></i>
        </button>
      </div>
    </nav>
  );
}
