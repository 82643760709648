import { useEffect, useState } from "react";

import useRequest from "./useRequest";
import idPicker from "helpers/idPicker";

export default function useScollContainer({ type, url, deleteUrl }) {
  const { reqTokenBase } = useRequest();
  const IdField = idPicker(type);

  // Estado de loading interno de la lista de items para mostrar si hay más datos para traer con el scroll o no
  const [loadingMore, setLoadingMore] = useState(false);
  // Estado de si ya no hay más datos por traer
  const [noMoreData, setNoMoreData] = useState(false);
  // Estado de si se encuentra borrando un item para que se detenga toda interacción
  const [erreasing, setErreasing] = useState(false);
  // Estado de loading inicial mientras carga la primera ronda de datos
  const [loading, setLoading] = useState(false);
  // Límite desde donde se traen los datos
  const [offset, setOffset] = useState(20);
  // Estado de error
  const [error, setError] = useState(false);
  // filtros
  const [keyword, setKeyword] = useState("");
  const [order, setOrder] = useState("");
  // Estado de la data
  const [data, setData] = useState([]);

  // Función inicial que trae los datos apenas se ingresa al componente
  const getData = async () => {
    setLoading(true);

    try {
      const res = await reqTokenBase.get(`${url}?l=20&o=0`);
      setData(res.data.datos);

      setLoading(false);
    } catch (error) {
      console.log(error);

      setError(true);
      setLoading(false);
    }
  };

  // Función que borra un item y lo quita de la lista
  const deleteItem = async (id) => {
    setErreasing(true);

    try {
      await reqTokenBase.delete(
        deleteUrl ? `${deleteUrl}/${id}` : `${url}/${id}`
      );
      setData(data.filter((item) => item[IdField] !== id));
      setErreasing(false);
    } catch (error) {
      console.log(error);
      setErreasing(false);
    }
  };

  // Función que trae los datos filtrados por keyword
  const handleFilters = async (keywordParams, orderParams) => {
    setLoading(true);

    setData([]);

    setKeyword(keywordParams);
    setOrder(orderParams);

    setOffset(0);
    setError(false);
    setLoadingMore(false);
    setNoMoreData(false);

    try {
      const res = await reqTokenBase.get(
        `${url}?k=${keywordParams}&l=20&o=0&b=${orderParams}`
      );

      if (res.data.datos.length === 0) return setNoMoreData(true);

      setData(res.data.datos);
      setLoading(false);
    } catch (error) {
      console.log(error);

      setError(true);
      setLoading(false);
    }
  };

  // Función que trae más datos al hacer scroll
  const handleScroll = async (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

    // Si hay keyword no hace nada
    // if (keyword) return;

    // Si no hay data no hace nada
    if (data.length === 0) return;

    // Si la lista es muy corta, no hace nada ni muestra los componentes interiores de "loadingMore" y "noMoreData"
    if (scrollHeight < 100) return;

    // Si la lista es larga, muestra el loader interior de "loadingMore" para mostrar que hay más datos
    if (scrollHeight > 300 && !noMoreData) setLoadingMore(true);

    // Función que trae más datos a partir del offset actual, si no hay más datos cambia "noMoreData"
    if (scrollHeight - scrollTop === clientHeight && !noMoreData) {
      try {
        const res = await reqTokenBase.get(
          `${url}?l=20&o=${offset}&k=${keyword}&b=${order}`
        );

        if (res.data.datos.length > 0) {
          setData([...data, ...res.data.datos]);
          setOffset(offset + 20);
          return;
        }

        setNoMoreData(true);
        setLoadingMore(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Reload de la data a mano
  const handleReload = () => {
    setData([]);
    getData();
    setOffset(20);
    setLoadingMore(false);
    setNoMoreData(false);
  };

  useEffect(() => {
    getData();

    //eslint-disable-next-line
  }, []);

  return {
    loading,
    error,
    data,
    deleteItem,
    erreasing,
    loadingMore,
    noMoreData,
    handleScroll,
    handleFilters,
    keyword,
    handleReload,
  };
}
