import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useRequest from "hooks/useRequest";

import CustomerForm from "components/CustomerForm/CustomerForm";
import BusinessList from "components/BusinessList/BusinessList";
import ScrollContainer from "components/ScrollContainer";

export default function CustomerFormPage() {
  const { reqToken } = useRequest();
  const { id } = useParams();

  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);

  const getCustomer = async () => {
    setLoading(true);

    const res = await reqToken.get(`customers/${id}`);
    setCustomer(res.data);

    setLoading(false);
  };

  const handleChange = ({ target }) => {
    setCustomer({ ...customer, [target.name]: target.value });
  };

  const handleSave = async (e) => {
    e.preventDefault();

    console.log(customer);
  };

  useEffect(() => {
    if (id !== "0") {
      return getCustomer();
    }

    setLoading(false);
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div className="py-5 d-flex align-items-center justify-content-center w-100 border bg-light">
        <div className="spinner-border text-dark" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="w-100 shadow border d-flex flex-column align-items-center justify-content-center rounded px-3 pb-3 bg-light">
      <h3 className="text-center py-4">
        {id !== "0" ? "Modificar contacto" : "Crear contacto"}
        <i className="fas fa-user-edit ms-2"></i>
      </h3>

      <div className="row row-cols-1 row-cols-lg-2 w-100 mx-0 g-3">
        <div className={id !== "0" ? "col" : "w-100"}>
          <div className="d-flex flex-column border rounded">
            <p className="w-100 py-2 px-3 text-start bg-success rounded-top text-white fw-bold mb-0">
              Formulario
            </p>

            <CustomerForm
              handleChange={handleChange}
              handleSave={handleSave}
              {...customer}
            />
          </div>
        </div>

        {id !== "0" && (
          <div className="col">
            <div className="d-flex flex-column border rounded">
              <BusinessList
                url="customers"
                relationId={customer.cus_int_id}
                relationField="cus_int_id"
                checkedField="bxcchecked"
                height={264}
              />
            </div>
          </div>
        )}
      </div>

      {id !== "0" && (
        <div className="d-flex flex-column border rounded w-100 mt-4">
          <p className="w-100 py-2 px-3 text-start bg-dark rounded-top text-white fw-bold mb-0">
            Archivos adjuntos
          </p>
          <div className="w-100 p-2 pt-3">
            <ScrollContainer
              url={`administration/files_customers/${customer.cus_int_id}`}
              deleteUrl="files_customers"
              uploadUrl="files_customers"
              headers={<Headers />}
              type="customer_file"
              noAdd
              upload
              formDistance="4"
              id={customer.cus_int_id}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const Headers = () => {
  return (
    <div className="row w-100 mx-0 px-0 bg-secondary rounded mb-3">
      <span className="col-3 col-md-1 fw-bold border-end py-2 text-center text-white">
        ID
      </span>
      <span className="col-9 col-md-9 fw-bold border-end py-2 text-white">
        Archivo
      </span>
      <span className="d-none d-md-block col-4 col-md-2 fw-bold text-center py-2 text-white">
        Opciones
      </span>
    </div>
  );
};
