import React from "react";

export default function OptionsButtons({ saveIssue }) {
  // prettier-ignore
  return (
    <div className="btn-group" role="group" aria-label="Selección de opciones">
      <button onClick={saveIssue} type="button" className="btn btn-success btn-sm"><i className="fas fa-save me-2"></i> Guardar</button>
      <button type="button" className="btn btn-danger btn-sm"><i className="fas fa-times me-2"></i> Detener seguimiento</button>
    </div>
  )
}
