import React from "react";

import HomeListItem from "components/Home/HomeListItem";
import CustomersListItem from "components/Contacts/CustomersListItem";
import FileListItem from "components/Issue/FileListItem";
import ManagementListItem from "components/Management/ManagementListItem";

export default function ListItemSelector({
  type,
  data,
  deleteItem,
  erreasing,
}) {
  if (type === "home") {
    return data.map((item) => (
      <HomeListItem
        key={item.isu_int_id}
        id={item.isu_int_id}
        title={item.isu_text_description}
        item={item}
        deleteItem={deleteItem}
        erreasing={erreasing}
      />
    ));
  }

  if (type === "customers") {
    return data.map((item) => (
      <CustomersListItem
        key={item.cus_int_id}
        id={item.cus_int_id}
        title={`${item.cus_text_name} ${item.cus_text_lastname}`}
        item={item}
        deleteItem={deleteItem}
        erreasing={erreasing}
      />
    ));
  }

  if (type === "issue_file") {
    return data.map((item) => (
      <FileListItem
        key={item.fxi_int_id}
        id={item.fxi_int_id}
        title={item.fxi_text_file}
        item={item}
        deleteItem={deleteItem}
        erreasing={erreasing}
        module="issues"
      />
    ));
  }

  if (type === "customer_file") {
    return data.map((item) => (
      <FileListItem
        key={item.fxc_int_id}
        id={item.fxc_int_id}
        title={item.fxc_text_file}
        item={item}
        deleteItem={deleteItem}
        erreasing={erreasing}
        module="customers"
      />
    ));
  }

  if (type === "business_file") {
    return data.map((item) => (
      <FileListItem
        key={item.fxb_int_id}
        id={item.fxb_int_id}
        title={item.fxb_text_file}
        item={item}
        deleteItem={deleteItem}
        erreasing={erreasing}
        module="business"
      />
    ));
  }

  if (type === "topics_file") {
    return data.map((item) => (
      <FileListItem
        key={item.fxt_int_id}
        id={item.fxt_int_id}
        title={item.fxt_text_file}
        item={item}
        deleteItem={deleteItem}
        erreasing={erreasing}
        module="topics"
      />
    ));
  }

  if (type === "users") {
    return data.map((item) => (
      <ManagementListItem
        key={item.use_int_id}
        id={item.use_int_id}
        title={`${item.use_text_name} ${item.use_text_lastname}`}
        item={item}
        deleteItem={deleteItem}
        erreasing={erreasing}
        type={type}
      />
    ));
  }

  if (type === "groups") {
    return data.map((item) => (
      <ManagementListItem
        key={item.gro_int_id}
        id={item.gro_int_id}
        title={item.gro_text_description}
        item={item}
        deleteItem={deleteItem}
        erreasing={erreasing}
        type={type}
      />
    ));
  }

  if (type === "access") {
    return data.map((item) => (
      <ManagementListItem
        key={item.acc_int_id}
        id={item.acc_int_id}
        title={item.acc_text_description}
        item={item}
        deleteItem={deleteItem}
        erreasing={erreasing}
        type={type}
      />
    ));
  }

  if (type === "business") {
    return data.map((item) => (
      <ManagementListItem
        key={item.bus_int_id}
        id={item.bus_int_id}
        title={item.bus_text_name}
        item={item}
        deleteItem={deleteItem}
        erreasing={erreasing}
        type={type}
      />
    ));
  }

  if (type === "topics") {
    return data.map((item) => (
      <ManagementListItem
        key={item.top_int_id}
        id={item.top_int_id}
        title={item.top_text_name}
        item={item}
        deleteItem={deleteItem}
        erreasing={erreasing}
        type={type}
      />
    ));
  }

  if (type === "logs") {
    return data.map((item) => (
      <ManagementListItem
      // key={item.fxc_int_id}
      // id={item.fxc_int_id}
      // title={item.fxc_text_file}
      // item={item}
      // deleteItem={deleteItem}
      // erreasing={erreasing}
      // type={type}
      />
    ));
  }

  if (type === "members") {
    return data.map((item) => (
      <ManagementListItem
        key={item.mem_int_id}
        id={item.mem_int_id}
        title={item.use_text_names}
        item={item}
        deleteItem={deleteItem}
        erreasing={erreasing}
        type={type}
      />
    ));
  }

  if (type === "types") {
    return data.map((item) => (
      <ManagementListItem
        key={item.typ_int_id}
        id={item.typ_int_id}
        title={item.typ_text_name}
        item={item}
        deleteItem={deleteItem}
        erreasing={erreasing}
        type={type}
      />
    ));
  }

  if (type === "payments") {
    return data.map((item) => (
      <ManagementListItem
        key={item.pay_int_id}
        id={item.pay_int_id}
        title={item.pay_text_reference}
        item={item}
        deleteItem={deleteItem}
        erreasing={erreasing}
        type={type}
      />
    ));
  }

  if (type === "expenses") {
    return data.map((item) => (
      <ManagementListItem
        key={item.exp_int_id}
        id={item.exp_int_id}
        title={item.exp_text_reference}
        item={item}
        deleteItem={deleteItem}
        erreasing={erreasing}
        type={type}
      />
    ));
  }
}
