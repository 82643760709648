import { useState } from "react";
import { useHistory } from "react-router-dom";

import useRefresh from "./useRefresh";
import useRequest from "./useRequest";

export default function useLogin() {
  const {
    setRefreshModal,
    refreshModal,
    autoCloseSession,
    refreshTokenCountdownStart,
    cancelRefresh,
    cancelAutoClose,
  } = useRefresh();

  const { reqLogin } = useRequest();

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(
    sessionStorage.getItem("userData")
      ? { userData: { ...JSON.parse(sessionStorage.getItem("userData")) } }
      : null
  );
  const history = useHistory();

  const login = async (data, email) => {
    setUser(data.body);

    sessionStorage.setItem("access_token", data.body.access_token);
    sessionStorage.setItem("refresh_token", data.body.refresh_token);
    sessionStorage.setItem("userData", JSON.stringify(data.body.userData));

    // email user enconde base 64
    sessionStorage.setItem("emuecb", btoa(email));

    // user last Login (now)
    sessionStorage.setItem("dull", btoa(new Date().getTime()));

    // start countdown for refresh
    refreshTokenCountdownStart();
  };

  const logout = () => {
    // remove possible timeouts
    cancelRefresh();
    cancelAutoClose();

    setUser(null);

    sessionStorage.clear();

    history.push("/");
  };

  const isLogged = () => {
    return !!user;
  };

  const refreshToken = async () => {
    // remove possible timeouts
    cancelRefresh();
    cancelAutoClose();

    const thisLoginDate = new Date().getTime();
    const refreshToken = sessionStorage.getItem("refresh_token");

    let lastLoginDate = sessionStorage.getItem("dull");
    lastLoginDate = atob(lastLoginDate);

    let userData = sessionStorage.getItem("userData");
    userData = JSON.parse(userData);

    let email = sessionStorage.getItem("emuecb");

    // if no email in storage, need to login
    if (!email) return;

    // set email as decoded string
    email = atob(email);

    // if email, need to compare last token date against token now, time cant be greater than 3600 seconds or 1 hour
    const timeDifference = (thisLoginDate - lastLoginDate) / 1000;

    // if token expired, require login
    if (timeDifference > 3600) return history.push("/");

    // set context user with prev userData info from storage to allow instant redirect to home while refresh full data with the API call
    setUser({ userData });

    try {
      setLoading(true);

      const res = await reqLogin.post("user/login", {
        refresh_token: refreshToken,
        username: email,
        grant_type: "refresh_token",
      });

      setUser(res.data.body);

      sessionStorage.setItem("access_token", res.data.body.access_token);
      sessionStorage.setItem("refresh_token", res.data.body.refresh_token);
      sessionStorage.setItem(
        "userData",
        JSON.stringify(res.data.body.userData)
      );

      // email user enconde base 64
      sessionStorage.setItem("emuecb", btoa(email));

      // user last Login (now)
      sessionStorage.setItem("dull", btoa(new Date().getTime()));

      // start countdown for refresh
      refreshTokenCountdownStart();

      // close modal
      document.getElementById("closeRefreshModalButton").click();

      // restart show modal state
      setRefreshModal(false);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return {
    loading,
    user,
    login,
    logout,
    isLogged,
    refreshToken,
    refreshModal,
    autoCloseSession,
  };
}
