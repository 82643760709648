import React, { useEffect, useState } from "react";
import useRequest from "hooks/useRequest";

import Modal from "./Modal";
import DeleteModal from "./DeleteModal";

import "styles/Contact.css";

export default function AccessByGroupsList({
  url,
  relationId,
  height,
  noEdit,
}) {
  const { reqToken } = useRequest();

  // while loading business info
  const [loading, setLoading] = useState(false);

  // while deleting relation
  const [errasing, setErrasing] = useState(false);

  // business data
  const [data, setData] = useState([]);

  // get business info
  const getData = async () => {
    setLoading(true);

    const listPromise = await reqToken.get(
      `vwlistaccessgroupsusers/${relationId}?l=999&o=0`
    );

    setData(listPromise.data);

    setLoading(false);
  };

  // remove business from list
  const removeItem = (id) => {
    setData(data.filter((item) => item.acc_int_id !== id));
  };

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="w-100">
      <div className="d-flex flex-column border rounded w-100">
        <p className="w-100 py-2 px-3 text-start bg-warning rounded-top text-dark fw-bold mb-0">
          Accesos disponibles por grupos
        </p>

        <div className="d-flex flex-column w-100 p-2">
          <Headers />

          <div
            className="business-container"
            style={{ height, maxHeight: height }}
          >
            {loading && (
              <div className="d-flex justify-content-center flex-grow-1 align-items-center">
                <div
                  className="spinner-border"
                  role="status"
                  // style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            {!loading && data.length === 0 && (
              <div className="alert alert-warning text-center py-2">
                No hay accesos relacionados a este usuario
              </div>
            )}

            {!loading &&
              data.length > 0 &&
              data.map((item) => (
                <ListItem
                  key={item.acc_int_id}
                  url={url}
                  id={item.acc_int_id}
                  title={item.acc_text_description}
                  relationId={relationId}
                  removeItem={removeItem}
                  errasing={errasing}
                  setErrasing={setErrasing}
                  noEdit={noEdit}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const ListItem = ({
  url,
  id,
  title,
  relationId,
  removeItem,
  errasing,
  setErrasing,
  noEdit,
}) => {
  const { reqToken } = useRequest();

  const [getData, setGetData] = useState(false);

  const handleDelete = async () => {
    setErrasing(true);

    const res = await reqToken.post(`access_${url}`, {
      acc_int_id: id,
      use_int_id: relationId,
      que: "d",
    });

    setErrasing(false);

    if (res.data.status === 204) {
      removeItem(id);
    }
  };

  return (
    <div className="row w-100 mx-0 px-0 border align-items-center">
      <span className="d-none d-lg-block col-lg-2 border-end py-1 text-center">
        {id}
      </span>
      <span className="col-12 col-md-8 col-lg-7 border-end py-1">{title}</span>
      <span className="d-none d-md-flex col-4 col-md-4 col-lg-3 py-1 gap-2 justify-content-center">
        <button
          onClick={() => setGetData(true)}
          type="button"
          className="btn btn-outline-secondary btn-sm"
          data-bs-toggle="modal"
          data-bs-target={`#access_by_group-modal-${id}`}
          disabled={errasing ? true : false}
        >
          <i className="far fa-eye"></i>
        </button>

        {!noEdit && (
          <button
            type="button"
            className="btn btn-danger btn-sm"
            data-bs-toggle="modal"
            data-bs-target={`#access_by_group-modal-delete-${id}`}
            disabled={errasing ? true : false}
          >
            <i className="far fa-trash-alt"></i>
          </button>
        )}
      </span>

      <Modal id={id} getData={getData} />
      <DeleteModal id={id} title={title} handleDelete={handleDelete} />
    </div>
  );
};

const Headers = () => {
  return (
    <div className="row w-100 mx-0 px-0 rounded border-bottom mb-2">
      <span className="d-none d-lg-block col-lg-2 fw-bold text-center py-1">
        ID
      </span>
      <span className="col-12 col-md-8 col-lg-7 fw-bold py-1">Acceso</span>
      <span className="d-none d-md-block col-4 col-md-4 col-lg-3 fw-bold text-center py-1">
        Opciones
      </span>
    </div>
  );
};
