import React from "react";

export default function DeleteModal({ id, title, handleDelete }) {
  return (
    <div
      className="modal fade"
      id={`groups-modal-delete-${id}`}
      tabIndex="-1"
      aria-labelledby={`groups-modal-delete-${id}-label`}
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title text-danger"
              id={`groups-modal-delete-${id}-label`}
            >
              <i className="fas fa-exclamation-triangle me-2"></i>
              Eliminar relación
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body py-5">
            <p className="text-center h5 text-danger pb-2">
              Estás por eliminar la relación de este usuario con el siguiente
              grupo
            </p>

            <ul className="list-group list-group-flush w-100">
              <li className="list-group-item">
                <div className="row mx-0">
                  <span className="col-4 fw-bold text-start">ID</span>
                  <span className="col-8  text-start">{id}</span>
                </div>
              </li>

              <li className="list-group-item">
                <div className="row mx-0">
                  <span className="col-4 fw-bold text-start">Nombre</span>
                  <span className="col-8  text-start">{title}</span>
                </div>
              </li>
            </ul>
          </div>
          <div className="modal-footer">
            <button
              onClick={handleDelete}
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              <i className="fas fa-trash-alt me-2"></i>
              Borrar
            </button>

            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
