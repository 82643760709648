import React, { useEffect, useState } from "react";

import useRequest from "hooks/useRequest";
import useAuth from "hooks/useAuth";

import BusinessList from "components/BusinessList/BusinessList";
import GroupsList from "components/GroupsList/GroupsList";
import AccessList from "components/AccessList/AccessList";
import AccessByGroupsList from "components/AccessByGroups/AccessByGroupsList";

import { DefaultInput, DefaultPassword } from "templates/input";

export default function Profile() {
  const { reqToken } = useRequest();
  const auth = useAuth();

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUserData] = useState(null);
  const [passConfirm, setPassConfirm] = useState("");
  const [view, setView] = useState({
    pass: false,
    passConfirm: false,
  });

  const getUser = async () => {
    setLoading(true);

    const res = await reqToken.get(`users/${auth.user.userData.id}`);
    setUserData(res.data);

    setLoading(false);
  };

  const handleChange = ({ target }) => {
    setUserData({ ...user, [target.name]: target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(null);

    if (user.use_text_pass !== passConfirm)
      return setError({
        ...error,
        passConfirm: "Las contraseñas no coinciden",
      });

    setSaving(true);

    const res = await reqToken.put(`users/${auth.user.userData.id}`, user);
    console.log(res);

    setSaving(false);
  };

  const viewPass = () => setView({ ...view, pass: !view.pass });
  //prettier-ignore
  const viewPassConfirm = () => setView({ ...view, passConfirm: !view.passConfirm });
  const handleConfirm = ({ target }) => setPassConfirm(target.value);

  useEffect(() => {
    getUser();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="w-100 shadow border d-flex flex-column align-items-center justify-content-center rounded py-5 bg-light">
      <h2 className="text-center pb-4">Configurar perfíl</h2>

      {loading && (
        <div className="py-5 d-flex align-items-center justify-content-center w-100">
          <div className="spinner-border text-dark" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {!loading && (
        <>
          <form
            onSubmit={handleSubmit}
            className="row mx-0 w-100 pt-3 align-items-end"
            autoComplete="off"
          >
            <DefaultInput
              customClass="col-12 col-lg-6"
              label="Nombres"
              placeholder="Ingresa los nombres"
              value={user.use_text_name}
              inputName="use_text_name"
              onChange={handleChange}
            />
            <DefaultInput
              customClass="col-12 col-lg-6"
              label="Apellidos"
              placeholder="Ingresa los apellidos"
              value={user.use_text_lastname}
              inputName="use_text_lastname"
              onChange={handleChange}
            />
            <DefaultInput
              customClass="col-12 col-lg-6"
              label="Correo electrónico"
              type="email"
              placeholder="Ingresa un correo electrónico"
              value={user.use_text_user}
              inputName="use_text_user"
              onChange={handleChange}
            />
            <DefaultInput
              customClass="col-12 col-lg-6"
              label="Teléfono"
              type="tel"
              inputMode="numeric"
              placeholder="Ingresa un número de teléfono"
              value={user.use_text_phone}
              inputName="use_text_phone"
              onChange={handleChange}
            />
            <DefaultInput
              customClass="col-12"
              label="Dirección"
              placeholder="Ingresa una dirección"
              value={user.use_text_address}
              inputName="use_text_address"
              onChange={handleChange}
            />
            <DefaultPassword
              customClass="col-12 col-lg-6"
              label="Contreña (Si no la ingresa, se generará una y se le enviará por correo)"
              placeholder="Ingresa una contraseña"
              value={user.use_text_pass}
              inputName="use_text_pass"
              onChange={handleChange}
              onClick={viewPass}
              type={view.pass ? "text" : "password"}
            />

            <DefaultPassword
              customClass="col-12 col-lg-6"
              label="Confirmar contraseña"
              placeholder="Ingresa tu contraseña de nuevo"
              value={passConfirm}
              onChange={handleConfirm}
              onClick={viewPassConfirm}
              type={view.passConfirm ? "text" : "password"}
              error={error?.passConfirm}
            />

            <div className="col-12 d-flex justify-content-end">
              <button
                type="submit"
                className={`btn btn-${saving ? "secondary" : "primary"}`}
                disabled={saving ? true : false}
              >
                {saving ? (
                  <>
                    <i className="fas fa-circle-notch fa-spin me-2"></i>
                    Guardando...
                  </>
                ) : (
                  <>
                    <i className="fas fa-save me-2"></i>
                    Guardar
                  </>
                )}
              </button>
            </div>
          </form>

          <div className="row mx-0 w-100 g-3 justify-content-center mt-5">
            <div className="col-12 col-lg-6">
              <BusinessList
                url="users"
                relationId={auth.user.userData.id}
                height={210}
                noEdit
                checkedField="businesschecked"
              />
            </div>

            <div className="col-12 col-lg-6">
              <GroupsList
                url="users"
                relationId={auth.user.userData.id}
                height={210}
                noEdit
                checkedField="groupschecked"
              />
            </div>

            <div className="col-12 col-lg-6">
              <AccessList
                url="users"
                relationId={auth.user.userData.id}
                height={210}
                noEdit
                checkedField="accesschecked"
              />
            </div>

            <div className="col-12 col-lg-6">
              <AccessByGroupsList
                url="users"
                relationId={auth.user.userData.id}
                height={210}
                noEdit
                accessByGroups
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
