const idsTable = {
  home: "isu_int_id",
  contacts: "cus_int_id",
  issue_file: "fxi_int_id",
  customer_file: "fxc_int_id",
  business_file: "fxb_int_id",
  topics_file: "fxt_int_id",
  // members: "fxt_int_id",
  types: "typ_int_id",
  // payments: "fxt_int_id",
};

export default function idPicker(type) {
  return idsTable[type];
}
