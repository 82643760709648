import React from "react";
import { useHistory } from "react-router-dom";

import DeleteModal from "components/ListsComponents/DeleteModal";

export default function ManagementListItem({
  id,
  title,
  deleteItem,
  erreasing,
  type,
  item,
}) {
  const history = useHistory();

  const customDeleteId = "list-item-delete-" + id;
  const hashCustomDeleteId = "#" + customDeleteId.replaceAll(" ", "");

  const deleteFunction = () => {
    deleteItem(id);
  };

  const handleRedirect = () => {
    if (
      type === "members" ||
      type === "types" ||
      type === "payments" ||
      type === "expenses"
    ) {
      return history.push(`/memberships/${type}/${id}`);
    }
    history.push(`/management/${type}/${id}`);
  };

  const paymentRedirect = () => {
    history.push(`/memberships/payments/0/${id}`);
  };

  const membershipRedirect = () => {
    history.push(`/memberships/members/0/${id}`);
  };

  const titleClassNoMembers = "col-3 col-md-9 py-2";
  const titleClassMembers = "col-9 col-md-5 py-2 border-end";

  return (
    <div className="row w-100 mx-0 px-0 border-bottom align-items-center">
      <span className="col-3 col-md-1 border-end py-2 text-center">{id}</span>

      <span
        className={type !== "members" ? titleClassNoMembers : titleClassMembers}
      >
        {title}
      </span>

      {type === "members" && (
        <>
          <span className="d-none d-md-block col-md-2 border-end py-2 text-center">
            {item.comprometido}
          </span>
          <span className="d-none d-md-block col-md-2 py-2 text-center">
            {item.pagado || "0"}
          </span>
        </>
      )}

      {/* <div className="col-9 col-md-9 h-100">
        {type === "members" ? (
          <div className="d-flex align-items-start">
            <p className="flex-grow-1 d-inline mb-0">{title}</p>
            <div className="align-items-center justify-content-evenly gap-3 h-100 border-start ps-3 d-none d-md-flex">
              <p className="mb-0">
                <span className="fw-bold me-2">A pagar:</span>
                {item.comprometido}
              </p>
              <p className="mb-0">
                <span className="fw-bold me-2">Total:</span>
                {item.pagado || "0"}
              </p>
            </div>
          </div>
        ) : (
          <span className="w-100 text-truncate">{title}</span>
        )}
      </div> */}

      <div className="d-none d-md-flex border-start col-md-2 py-2 justify-content-center gap-3">
        {type === "members" && (
          <button
            onClick={paymentRedirect}
            type="button"
            className={`btn btn-${
              Number(item.pagado) < Number(item.comprometido)
                ? "danger"
                : "success"
            } btn-sm`}
            disabled={erreasing ? true : false}
            title="Crear pago"
          >
            <i className="fas fa-dollar-sign px-1"></i>
          </button>
        )}

        <button
          onClick={handleRedirect}
          type="button"
          className="btn btn-secondary btn-sm"
          disabled={erreasing ? true : false}
        >
          <i className="far fa-edit"></i>
        </button>

        <button
          type="button"
          className="btn btn-danger btn-sm"
          disabled={erreasing ? true : false}
          data-bs-toggle="modal"
          data-bs-target={hashCustomDeleteId}
        >
          <i className="far fa-trash-alt"></i>
        </button>

        {type === "users" && (
          <button
            onClick={membershipRedirect}
            type="button"
            className="btn btn-warning btn-sm"
            title="Crear membresía"
          >
            <i className="fas fa-id-card"></i>
          </button>
        )}
      </div>

      <DeleteModal
        customId={customDeleteId}
        title={title}
        id={id}
        deleteFunction={deleteFunction}
        erreasing={erreasing}
        homeType
      />
    </div>
  );
}
