const titleSelector = {
  users: "Usuarios",
  groups: "Grupos",
  access: "Accesos",
  business: "Empresas",
  topics: "Temas",
  logs: "Registros",
  members: "Miembros",
  types: "Tipos",
  payments: "Pagos",
};

export default titleSelector;
