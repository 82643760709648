import React, { useEffect, useState } from "react";
import useRequest from "hooks/useRequest";

import BusinessModal from "./BusinessModal";
import BusinessDeleteModal from "./BusinessDeleteModal";

import "styles/Contact.css";

export default function BusinessList({
  url,
  relationId,
  height,
  noEdit,
  relationField,
  checkedField,
}) {
  const { reqToken } = useRequest();

  // while loading business info
  const [loading, setLoading] = useState(false);

  // while deleting relation
  const [inProcess, setInProcess] = useState(false);

  // business data
  const [business, setBusiness] = useState([]);

  // get business info
  const getBusiness = async () => {
    setLoading(true);

    const contactBusinessPromise = await reqToken.get(
      `vwlistbusiness${url}/${relationId}?l=999&o=0`
    );

    if (noEdit) {
      setBusiness(
        contactBusinessPromise.data.datos.filter(
          (item) => item[checkedField] !== "0" && item[checkedField] !== null
        )
      );

      return setLoading(false);
    }

    setBusiness(contactBusinessPromise.data.datos);

    setLoading(false);
  };

  // change item to not assigned
  const removeBusiness = (id) => {
    setBusiness(
      business.map((item) =>
        item.bus_int_id === id ? { ...item, [checkedField]: null } : item
      )
    );
  };

  // change item to assinged
  const assignItem = (id) => {
    setBusiness(
      business.map((item) =>
        item.bus_int_id === id ? { ...item, [checkedField]: id } : item
      )
    );
  };

  useEffect(() => {
    getBusiness();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="w-100">
      <div className="d-flex flex-column border rounded w-100">
        <p className="w-100 py-2 px-3 text-start bg-primary rounded-top text-white fw-bold mb-0">
          Empresas asignadas
        </p>

        <div className="d-flex flex-column w-100 p-2">
          <HeadersBusiness />

          <div
            className="business-container"
            style={{ height, maxHeight: height }}
          >
            {loading && (
              <div className="d-flex justify-content-center flex-grow-1 align-items-center">
                <div
                  className="spinner-border"
                  role="status"
                  // style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            {!loading && business.length === 0 && (
              <div className="alert alert-warning text-center py-2">
                No hay empresas relacionadas a este usuario
              </div>
            )}

            {!loading &&
              business.length > 0 &&
              business.map((item) => (
                <BusinessItem
                  key={item.bus_int_id}
                  url={url}
                  id={item.bus_int_id}
                  title={item.bus_text_name}
                  // checked={item.bxcchecked}
                  checked={
                    item[checkedField] === "0" || item[checkedField] === null
                      ? false
                      : true
                  }
                  relationId={relationId}
                  removeBusiness={removeBusiness}
                  assignItem={assignItem}
                  inProcess={inProcess}
                  setInProcess={setInProcess}
                  noEdit={noEdit}
                  relationField={relationField}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const BusinessItem = ({
  url,
  id,
  title,
  checked,
  relationId,
  removeBusiness,
  assignItem,
  inProcess,
  setInProcess,
  noEdit,
  relationField,
}) => {
  const { reqToken } = useRequest();

  const [getData, setGetData] = useState(false);

  const handleAssign = async () => {
    setInProcess(true);

    const res = await reqToken.post(`business_${url}`, {
      bus_int_id: id,
      [relationField]: relationId,
      que: "c",
    });

    setInProcess(false);

    if (res.status === 200 || res.status === 201) {
      assignItem(id);
    }
  };

  const handleDelete = async () => {
    setInProcess(true);

    const res = await reqToken.post(`business_${url}`, {
      bus_int_id: id,
      [relationField]: relationId,
      que: "d",
    });

    setInProcess(false);

    if (res.status === 200) {
      removeBusiness(id);
    }
  };

  return (
    <div className="row w-100 mx-0 px-0 border align-items-center">
      <span className="d-none d-lg-block col-lg-2 border-end py-1 text-center">
        {id}
      </span>
      <span className="col-12 col-md-8 col-lg-7 border-end py-1 text-truncate">
        {title}
      </span>
      <span className="d-none d-md-flex col-4 col-md-4 col-lg-3 py-1 gap-2 justify-content-center">
        <button
          onClick={() => setGetData(true)}
          type="button"
          className="btn btn-outline-secondary btn-sm"
          data-bs-toggle="modal"
          data-bs-target={`#business-modal-${id}`}
          disabled={inProcess ? true : false}
        >
          <i className="far fa-eye"></i>
        </button>

        {!noEdit && !checked && (
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            disabled={inProcess ? true : false}
            onClick={handleAssign}
            title="Crear relación"
          >
            <i className="fas fa-check"></i>
          </button>
        )}

        {!noEdit && checked && (
          <button
            type="button"
            className="btn btn-success btn-sm"
            data-bs-toggle="modal"
            data-bs-target={`#business-modal-delete-${id}`}
            disabled={inProcess ? true : false}
            title="Eliminar relación"
          >
            <i className="fas fa-check"></i>
          </button>
        )}
      </span>

      <BusinessModal id={id} getData={getData} />
      <BusinessDeleteModal id={id} title={title} handleDelete={handleDelete} />
    </div>
  );
};

const HeadersBusiness = () => {
  return (
    <div className="row w-100 mx-0 px-0 rounded border-bottom mb-2">
      <span className="d-none d-lg-block col-lg-2 fw-bold text-center py-1">
        ID
      </span>
      <span className="col-12 col-md-8 col-lg-7 fw-bold py-1">Empresa</span>
      <span className="d-none d-md-block col-4 col-md-4 col-lg-3 fw-bold text-center py-1">
        Opciones
      </span>
    </div>
  );
};
