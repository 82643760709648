import ScrollContainer from "components/ScrollContainer";
import React from "react";

import { DefaultTextarea } from "templates/input";

export default function TopicsForm({
  id,
  handleSubmit,
  handleChange,
  loading,
  error,
  top_text_name,
}) {
  return (
    <div className="row w-100 g-3">
      <h3 className="text-center">
        {id !== "0" ? "Editar empresa" : "Nueva empresa"}
      </h3>
      <form
        onSubmit={handleSubmit}
        className={`${
          id !== "0" ? "col-12 col-lg-5 col-xl-6" : "col-12"
        } align-items-center`}
        autoComplete="off"
      >
        <DefaultTextarea
          customClass="col-12"
          label="Descripción"
          placeholder="Ingresa una descripción"
          value={top_text_name}
          inputName="top_text_name"
          onChange={handleChange}
          error={error.top_text_name}
        />

        <div className="col-12 d-flex justify-content-end">
          <button
            type="submit"
            className={`btn btn-${loading ? "secondary" : "primary"}`}
            disabled={loading ? true : false}
          >
            {loading ? (
              <>
                <i className="fas fa-circle-notch fa-spin me-2"></i>
                Guardando...
              </>
            ) : (
              <>
                <i className="fas fa-save me-2"></i>
                Guardar
              </>
            )}
          </button>
        </div>
      </form>

      {id !== "0" && (
        <div className="col-12 col-lg-7 col-xl-6">
          <div className="d-flex flex-column border rounded w-100">
            <p className="w-100 py-2 px-3 text-start bg-dark rounded-top text-white fw-bold mb-0">
              Archivos adjuntos
            </p>
            <div className="w-100 p-2 pt-3">
              <ScrollContainer
                url={`administration/files_topics/${id}`}
                deleteUrl="files_topics"
                uploadUrl="files_topics"
                headers={<Headers />}
                type="topics_file"
                noAdd
                upload
                formDistance="4"
                id={id}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const Headers = () => {
  return (
    <div className="row w-100 mx-0 px-0 bg-secondary rounded mb-3">
      <span className="col-3 col-md-1 fw-bold border-end py-2 text-center text-white">
        ID
      </span>
      <span className="col-9 col-md-9 fw-bold border-end py-2 text-white">
        Archivo
      </span>
      <span className="d-none d-md-block col-4 col-md-2 fw-bold text-center py-2 text-white">
        Opciones
      </span>
    </div>
  );
};
