import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import useScollContainer from "hooks/useScollContainer";

import { DefaultInput, DefaultSelect } from "templates/input";
import ListItemSelector from "./ListItemSelector";
import UploadButton from "components/Issue/UploadButton";

import routeTable from "./routeTable";

export default function ScrollContainer({
  url,
  deleteUrl,
  uploadUrl,
  headers,
  headersMembers,
  type,
  noAdd,
  upload,
  formDistance = "5",
  id,
}) {
  const {
    loading,
    error,
    data,
    deleteItem,
    erreasing,
    loadingMore,
    noMoreData,
    handleScroll,
    handleFilters,
    handleReload,
  } = useScollContainer({ type, url, deleteUrl });

  const history = useHistory();
  const [word, setWord] = useState("");
  const [order, setOrder] = useState("t");

  const handleWord = ({ target }) => {
    setWord(target.value);
  };

  const handleOrder = ({ target }) => {
    setOrder(target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleFilters(word, order);
  };

  const handleNew = () => {
    if (type === "home") {
      return history.push("newissue");
    }

    history.push(`${routeTable[type]}/0`);
  };

  return (
    <div className="w-100 d-flex flex-column">
      <form
        onSubmit={handleSubmit}
        className={`d-none d-md-flex w-100 align-items-end gap-3 mb-${formDistance}`}
        autoComplete="off"
      >
        <DefaultInput
          customClass="flex-grow-1"
          onChange={handleWord}
          value={word}
          placeholder="Ingresa palabras clave"
          mb="0"
          required={false}
        />

        {type === "members" && (
          <DefaultSelect
            mb="0"
            customClass="flex-grow-1"
            defaultValue={order}
            options={[
              { value: "t", name: "Orden - Todos" },
              { value: "a", name: "Orden - Ascendente" },
              { value: "d", name: "Orden - Descendente" },
            ]}
            onChange={handleOrder}
          />
        )}

        <button type="submit" className="btn btn-primary">
          <i className="fas fa-search me-2"></i>
          Buscar
        </button>

        {!noAdd && (
          <button onClick={handleNew} type="button" className="btn btn-success">
            <i className="fas fa-folder-plus me-2"></i>
            Agregar
          </button>
        )}

        {upload && (
          <UploadButton
            id={id}
            handleReload={handleReload}
            uploadEndpoint={uploadUrl}
          />
        )}
      </form>

      {type !== "members" ? headers : headersMembers}

      <div
        onScroll={handleScroll}
        className="items-list-container d-flex flex-column border-start border-end border-top w-100 rounded-top border-bottom"
      >
        {loading && (
          <div className="py-5 d-flex align-items-center justify-content-center w-100 border-bottom">
            <div className="spinner-border text-dark" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}

        {error && (
          <div className="py-5 d-flex align-items-center justify-content-center w-100 border-bottom">
            <div
              className="alert alert-danger w-75 mx-auto text-center mb-0"
              role="alert"
            >
              Un error ha ocurrido, intentalo más tarde !
            </div>
          </div>
        )}

        {!loading && !error && (
          <ListItemSelector
            type={type}
            data={data}
            deleteItem={deleteItem}
            erreasing={erreasing}
          />
        )}

        {loadingMore && (
          <div className="py-3 d-flex align-items-center justify-content-center w-100 border-bottom">
            <div className="spinner-border text-dark" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}

        {noMoreData && (
          <p className="w-100 py-3 mb-0 text-center fw-bold">
            No hay mas datos
          </p>
        )}

        {!loading && !error && data.length === 0 && (
          <div className="alert alert-warning text-center w-100 py-2 mb-0">
            No hay datos
          </div>
        )}
      </div>
    </div>
  );
}
