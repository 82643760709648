import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { AuthProvider } from "../auth/authContext";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

// Navigation
import Navbar from "components/Layout/Navbar";

// pages
import Login from "pages/Login";
import Home from "pages/Home";
import CustomersList from "pages/CustomersList";
import Issue from "pages/Issue";
import CustomerFormPage from "pages/CustomerFormPage";
import Management from "pages/Management";
import MembershipsMenu from "pages/MembershipsMenu";
import ModulePage from "pages/ModulePage";
import ModuleFormPage from "pages/ModuleFormPage";
import Profile from "pages/Profile";
import NewIssue from "pages/NewIssue";
import Calendar from "pages/Calendar";

// prettier-ignore
export default function AppRouter() {
  return (
    <Router>
      <AuthProvider>
        <Navbar />
        
        <div className="container py-5">
          <Switch>
            <PublicRoute exact path="/" component={Login} />

            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute exact path="/customers" component={CustomersList} />
            <PrivateRoute exact path="/management" component={Management} />
            <PrivateRoute exact path="/memberships" component={MembershipsMenu} />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/newissue" component={NewIssue} />
            <PrivateRoute exact path="/newissue/:date" component={NewIssue} />
            <PrivateRoute exact path="/calendar" component={Calendar} />

            <PrivateRoute exact path="/issues/:id" component={Issue} />
            <PrivateRoute exact path="/customer/:id" component={CustomerFormPage} />
            <PrivateRoute exact path="/management/:module" component={ModulePage} />
            <PrivateRoute exact path="/management/:module/:id" component={ModuleFormPage} />

            <PrivateRoute exact path="/memberships/:module" component={ModulePage} />
            <PrivateRoute exact path="/memberships/:module/:id" component={ModuleFormPage} />
            <PrivateRoute exact path="/memberships/:module/:id/:userId" component={ModuleFormPage} />
            
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
      </AuthProvider>
    </Router>
  );
}
