import React from "react";
import { DefaultInput } from "templates/input";

export default function CustomerForm({
  handleChange,
  handleSave,
  cus_text_name,
  cus_text_lastname,
  cus_text_email,
  cus_text_phone,
  cus_text_address,
}) {
  return (
    <form
      onSubmit={handleSave}
      className="row mx-0 w-100 py-3"
      autoComplete="off"
    >
      <DefaultInput
        customClass="col-12 col-lg-6"
        label="Nombre"
        placeholder="Ingresa un nombre"
        value={cus_text_name}
        inputName="cus_text_name"
        onChange={handleChange}
      />
      <DefaultInput
        customClass="col-12 col-lg-6"
        label="Apellido"
        placeholder="Ingresa un apellido"
        value={cus_text_lastname}
        inputName="cus_text_lastname"
        onChange={handleChange}
      />
      <DefaultInput
        customClass="col-12 col-lg-6"
        label="Correo electrónico"
        placeholder="Ingresa un correo electrónico"
        value={cus_text_email}
        inputName="cus_text_email"
        onChange={handleChange}
      />
      <DefaultInput
        customClass="col-12 col-lg-6"
        label="Teléfono"
        type="number"
        placeholder="Ingresa un número de teléfono"
        value={cus_text_phone}
        inputName="cus_text_phone"
        onChange={handleChange}
      />
      <DefaultInput
        customClass="col-12"
        label="Dirección"
        placeholder="Ingresa una dirección"
        value={cus_text_address}
        inputName="cus_text_address"
        onChange={handleChange}
      />

      <div className="col-12 d-flex">
        <button className="btn btn-success btn-sm">
          <i className="fas fa-save me-2"></i>
          Guardar
        </button>
      </div>
    </form>
  );
}
