import React, { useState } from "react";

import useRequest from "hooks/useRequest";
import useAuth from "hooks/useAuth";
import useForm from "hooks/useForm";

import logo from "images/logo.png";

import "styles/Login.css";

const BASE_URL = process.env.REACT_APP_ADMINISTRATION_BASE_URL;

export default function Login() {
  const { reqLogin } = useRequest();
  const auth = useAuth();

  const [loading, setLoading] = useState(false);

  // prettier-ignore
  const [values, handleInputChange] = useForm({
    email: BASE_URL === "https://ofv.test/administration/" ? "juancarlos@correovirtual.com" : "",
    password: BASE_URL === "https://ofv.test/administration/" ? "jc12345" : "",
  });

  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      setLoading(true);

      const res = await reqLogin.post("user/login", {
        username: values.email,
        password: values.password,
        grant_type: "password",
      });

      auth.login(res.data, values.email);

      // setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError("Acceso denegado, correo o contraseña incorrectos");
    }
  };

  return (
    <div className="login-screen bg-light d-flex align-items-center">
      <div className="row mx-0 w-100 justify-content-center">
        <h2 className="text-center mb-4">Acceso Oficina Virtual</h2>

        <form
          onSubmit={handleSubmit}
          className="border shadow rounded p-4 d-flex flex-column bg-light col-10 col-sm-8 col-md-7 col-lg-5 col-xl-4"
          autoComplete="off"
          autoCapitalize="off"
        >
          <div className="w-100 mb-4 d-flex justify-content-center">
            <img src={logo} alt="Logo" width="500" />
          </div>

          {error && (
            <div
              className="alert alert-danger w-100 mb-4 text-center"
              role="alert"
            >
              {error}
            </div>
          )}

          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Ingresa tu usuario"
              id="emailLogin"
              name="email"
              value={values.email}
              onChange={handleInputChange}
              readOnly={loading ? true : false}
            />
            <label htmlFor="emailLogin">Usuario</label>
          </div>

          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control"
              id="passLogin"
              placeholder="Contraseña"
              name="password"
              value={values.password}
              onChange={handleInputChange}
              readOnly={loading ? true : false}
            />
            <label htmlFor="passLogin">Contraseña</label>
          </div>

          <button
            type="submit"
            className={`btn btn-${loading ? "secondary" : "success"}`}
            disabled={loading ? true : false}
          >
            {loading ? (
              <>
                <i className="fas fa-circle-notch fa-spin me-2"></i>
                Cargando...
              </>
            ) : (
              <>
                <i className="fas fa-user me-2"></i>
                Ingresar
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
