import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { DefaultInput, DefaultSelect } from "templates/input";

import useRequest from "hooks/useRequest";
import TextEditor from "components/TextEditor";
import getDateString from "helpers/dateString";

export default function NewIssue() {
  const { date } = useParams();
  const { reqToken, reqTokenFormData } = useRequest();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const [data, setData] = useState({
    users: [],
    topics: [],
  });

  const [textEditor, setTextEditor] = useState("");
  const [files, setFiles] = useState([]);

  const [request, setRequest] = useState({
    next_date_string: date ?? "",
    next_date_number: date ? date.split("-").map((item) => Number(item)) : "",
    top_int_id: "",
    cus_int_id: "",
  });

  // get issue and topics data
  const getData = async () => {
    try {
      setLoading(true);

      const usersPromise = reqToken.get(`users?l=999&o=0`);
      const topicsPromise = reqToken.get("topics?l=999&o=0");

      const response = await Promise.all([usersPromise, topicsPromise]);

      setData({
        users: response[0].data.datos,
        topics: response[1].data.datos,
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  const handleUserChange = ({ target }) => {
    const id = target.value.split(" ")[0].replaceAll(/[^\w\s]/gi, "");

    const user = data.users.find((item) => item.use_int_id === id);

    if (user) {
      return setRequest({ ...request, cus_int_id: id });
    }

    setRequest({ ...request, cus_int_id: "" });
  };

  const handleFiles = ({ target }) => {
    if (target.files.length === 0) {
      // setFile(null);
      return;
    }

    setFiles(target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError({});
    setLoading(true);

    const data = new FormData();
    data.append("cus_int_id", request.cus_int_id);
    data.append("top_int_id", request.top_int_id);
    data.append("isu_text_description", textEditor);
    data.append("files", files);

    try {
      await reqTokenFormData.post("issues", data);
      history.push("/home");
    } catch (error) {
      console.log({ ...error });
      setError(error.response.data.messages);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, []);

  return (
    <form
      className="w-100 h-100 d-flex flex-column align-items-center card p-3 p-xl-5 bg-light gap-3"
      autocomplete="off"
      onSubmit={handleSubmit}
    >
      <h3 className="text-center mb-2">Nuevo pendiente</h3>

      <div className="row w-100 mx-0">
        <div className="col-12 col-lg-8 mb-3 mb-lg-0">
          <div className="d-flex flex-column border rounded h-100">
            <p className="w-100 py-2 px-3 text-start bg-warning rounded-top text-dark fw-bold mb-0">
              Compromiso actual
            </p>
            <div className="w-100 p-2 h-100">
              <TextEditor
                height="292"
                onChange={(value) => setTextEditor(value)}
              />
              {error.isu_text_description && (
                <p className="text-danger mb-1 mt-2 word-break">
                  {error.isu_text_description}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-4">
          <div className="d-flex flex-column border rounded mb-3">
            <p className="w-100 py-2 px-3 text-start bg-secondary rounded-top text-white fw-bold mb-0">
              Tema
            </p>
            <div className="w-100 p-2 flex-grow-1 d-flex align-items-center">
              <DefaultSelect
                customClass="w-100"
                mb="0"
                placeholder="Selecciona un tema"
                error={error.top_int_id}
                options={data.topics.map((item) => ({
                  value: item.top_int_id,
                  name: item.top_text_name,
                }))}
                onChange={({ target }) =>
                  setRequest({ ...request, top_int_id: target.value })
                }
              />
            </div>
          </div>

          <div className="d-flex flex-column border rounded mb-3">
            <p className="w-100 py-2 px-3 text-start bg-primary rounded-top text-white fw-bold mb-0">
              Cliente
            </p>
            <div className="w-100 p-2 flex-grow-1 d-flex align-items-center">
              <input
                list="users"
                className="form-control"
                placeholder="Seleccione un usuario"
                onChange={handleUserChange}
              />
              <datalist id="users">
                {data.users.map((item) => (
                  <option
                    value={`[${item.use_int_id}] - ${item.use_text_name} ${item.use_text_lastname}`}
                    key={item.use_int_id}
                  />
                ))}
              </datalist>
            </div>
            {error.cus_int_id && (
              <p className="text-danger mb-1 px-2 word-break">
                {error.cus_int_id}
              </p>
            )}
          </div>
          <div className="d-flex flex-column border rounded">
            <p className="w-100 py-2 px-3 text-start bg-success rounded-top text-white fw-bold mb-0">
              Próxima cita
            </p>
            <div className="d-flex w-100 p-2 gap-2 align-items-end">
              <DefaultInput
                required={false}
                customClass="w-100"
                label="Próximo contacto"
                type="date"
                mb="0"
                // error={error.}
                value={request.next_date_string}
                onChange={({ target }) =>
                  setRequest({
                    ...request,
                    next_date_string: target.value,
                    next_date_number: target.value
                      .split("-")
                      .map((item) => Number(item)),
                  })
                }
              />
            </div>

            <p className="w-100 my-2 px-2 text-center">
              <span className="fw-bold me-2">Próxima cita fijada en:</span>
              <br />
              {request.next_date_number
                ? getDateString(
                    new Date(
                      request.next_date_number[0],
                      request.next_date_number[1],
                      request.next_date_number[2]
                    )
                  )
                : "Sin fecha fijada"}
            </p>
          </div>
        </div>

        <div className="mt-3 mb-3">
          <label htmlFor="files" className="form-label">
            Subir archivos
          </label>
          <input
            className="form-control"
            type="file"
            id="files"
            multiple
            onChange={handleFiles}
          />
        </div>
      </div>

      <button
        className="btn btn-success ms-auto"
        type="submit"
        disabled={loading ? true : false}
      >
        {loading ? (
          <>
            <i className="fas fa-circle-notch fa-spin me-2"></i>
            Cargando...
          </>
        ) : (
          <>
            <i className="fas fa-save me-2"></i>
            Crear
          </>
        )}
      </button>
    </form>
  );
}
