import React from "react";
import { Link } from "react-router-dom";

import "styles/Management.css";

export default function Management() {
  const data = [
    {
      id: 1,
      icon: "fas fa-user-cog me-2",
      title: "Usuarios",
      desc: "Gestión de usuarios, sus grupos y accesos",
      route: "/management/users",
    },
    {
      id: 2,
      icon: "fas fa-users-cog me-2",
      title: "Grupos",
      desc: "Gestión de grupos, para asignar a los usuarios",
      route: "/management/groups",
    },
    {
      id: 3,
      icon: "fas fa-id-card me-2",
      title: "Permisos",
      desc: "Permite establecer los tipos de accesos dentro del sistema",
      route: "/management/access",
    },
    {
      id: 4,
      icon: "fas fa-building me-2",
      title: "Empresas",
      desc: "Gestión de empresas",
      route: "/management/business",
    },
    {
      id: 5,
      icon: "fas fa-folder me-2",
      title: "Temas",
      desc: "Gestión de los temas para clasificar los compromisos",
      route: "/management/topics",
    },
    {
      id: 9,
      icon: "fas fa-history me-2",
      title: "Logs",
      desc: "Visualiza la bitácora de accesos al sistema",
      route: "/management",
    },
    {
      id: 10,
      icon: "fas fa-user me-2",
      title: "Mi perfil",
      desc: "Configurar mi perfíl",
      route: "/profile",
    },
  ];

  return (
    <div className="w-100 shadow border d-flex flex-column align-items-center justify-content-center rounded py-5 bg-light">
      <h2 className="text-center pb-4">Opciones del sistema</h2>

      <div className="items-container d-flex flex-wrap mx-auto align-items-center justify-content-center gap-3">
        {data.map((item) => (
          <Link
            key={item.id}
            to={item.route}
            className="management-link px-3 py-4 text-decoration-none text-dark border d-flex flex-column align-items-center justify-content-center rounded"
          >
            <h5 className="text-center mb-4 text-decoration-none">
              <i className={item.icon}></i>
              {item.title}
            </h5>
            <p className="text-center w-100 mb-0 text-decoration-none">
              {item.desc}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
}
