const table = {
  home: "administration/home",
  customers: "administration/customers",
  issue_file: "administration/issue_file",
  customer_file: "administration/customer_file",
  business_file: "administration/business_file",
  topics_file: "administration/topics_file",
  users: "administration/users",
  groups: "administration/groups",
  access: "administration/access",
  business: "administration/business",
  topics: "administration/topics",
  logs: "administration/logs",
  members: "memberships/members",
  types: "memberships/types",
  payments: "memberships/payments",
  expenses: "memberships/expenses",
};

export default function moduleTable(module) {
  return table[module];
}
