import React from "react";

export default function PaymentsHistory({ data }) {
  const totalCash = (data) => {
    const total = data.reduce((acc, el) => acc + Number(el.pay_int_value), 0);

    return total;
  };

  return (
    <div className="w-100 mt-5">
      <h2 className="text-center">Historial de pagos</h2>

      <Headers />

      {data.length > 0 &&
        data.map((item, index) => (
          <section key={index} className="w-100">
            <div
              className="row w-100 mx-0 px-0 border"
              data-bs-toggle="collapse"
              href={`#collapse_${index}`}
              role="button"
              aria-expanded="false"
              aria-controls={`collapse_${index}`}
            >
              <span className="col-4 col-md-2 border-end py-2 text-center">
                {item.mem_int_id}
              </span>
              <span className="col-4 col-md-2 border-end py-2 text-center">
                {item.mem_data_from
                  ? item.mem_data_from.split(" ")[0]
                  : "Activo"}
              </span>
              <span className="col-4 col-md-2 border-end py-2 text-center">
                {item.mem_data_until
                  ? item.mem_data_until.split(" ")[0]
                  : "Activo"}
              </span>
              <span className="d-none d-md-block col-md-2 border-end py-2 text-center">
                $ {item.mem_int_value_commitment}
              </span>
              <span className="d-none d-md-block col-md-2 border-end py-2 text-center">
                {item.cuotas_cantidad}
              </span>
              <span className="d-none d-md-block col-md-2 text-center py-2">
                {totalCash(item.pagos_realizados) > item.cuotas_dinero ? (
                  <span className="text-success">{item.cuotas_dinero}</span>
                ) : (
                  <span className="text-danger">{item.cuotas_dinero}</span>
                )}
              </span>
            </div>
            <div className="collapse" id={`collapse_${index}`}>
              {item.pagos_realizados.map((payment, index) => (
                <div
                  key={index}
                  className="border-end border-start p-3 d-flex flex-wrap border-bottom"
                >
                  <p className="w-50 word-break mb-1">
                    <span className="me-2">Fecha:</span>
                    {payment.pay_date_creation_date}
                  </p>
                  <p className="w-50 word-break mb-1">
                    <span className="fw-bold me-2">Valor:</span>
                    {payment.pay_int_value}
                  </p>
                  <p className="w-50 word-break mb-0">
                    <span className="me-2">Referencia:</span>
                    {payment.pay_text_reference}
                  </p>
                  <p className="w-50 word-break mb-0">
                    <span className="me-2">Observaciones:</span>
                    {payment.pay_text_observations}
                  </p>
                </div>
              ))}
            </div>
          </section>
        ))}
    </div>
  );
}

const Headers = () => {
  return (
    <div className="row w-100 mx-0 px-0 bg-secondary rounded mb-3 mt-3">
      <span className="col-4 col-md-2 fw-bold border-end py-2 text-center text-white">
        ID
      </span>
      <span className="col-4 col-md-2 fw-bold border-end py-2 text-white text-center">
        Desde
      </span>
      <span className="col-4 col-md-2 fw-bold border-end py-2 text-white text-center">
        Hasta
      </span>
      <span className="d-none d-md-block col-md-2 fw-bold border-end py-2 text-white text-center">
        A pagar
      </span>
      <span className="d-none d-md-block col-md-2 fw-bold border-end py-2 text-white text-center">
        Cuotas
      </span>
      <span className="d-none d-md-block col-md-2 fw-bold text-center py-2 text-white">
        Total
      </span>
    </div>
  );
};
