import React from "react";
import { useHistory } from "react-router-dom";

import DeleteModal from "components/ListsComponents/DeleteModal";

export default function CustomersListItem({
  id,
  title,
  deleteItem,
  erreasing,
}) {
  const history = useHistory();

  const customDeleteId = "list-item-delete-" + id;
  const hashCustomDeleteId = "#" + customDeleteId.replaceAll(" ", "");

  const deleteFunction = () => {
    deleteItem(id);
  };

  const handleEdit = () => {
    history.push(`customer/${id}`);
  };

  return (
    <div className="row w-100 mx-0 px-0 border-bottom align-items-center">
      <span className="d-none d-md-block col-md-1 border-end py-2 text-center">
        {id}
      </span>
      <span className="col-7 col-md-9 border-end py-2">{title}</span>

      <div className="d-flex col-5 col-md-2 py-2 justify-content-center gap-3">
        <button
          onClick={handleEdit}
          type="button"
          className="btn btn-secondary btn-sm"
          disabled={erreasing ? true : false}
        >
          <i className="far fa-edit"></i>
        </button>

        <button
          type="button"
          className="btn btn-danger btn-sm"
          disabled={erreasing ? true : false}
          data-bs-toggle="modal"
          data-bs-target={hashCustomDeleteId}
        >
          <i className="far fa-trash-alt"></i>
        </button>
      </div>

      <DeleteModal
        customId={customDeleteId}
        title={title}
        id={id}
        deleteFunction={deleteFunction}
        erreasing={erreasing}
      />
    </div>
  );
}
