import React, { useEffect, useState } from "react";
import useRequest from "hooks/useRequest";

export default function Modal({ id, getData }) {
  const { reqToken } = useRequest();

  const [data, setData] = useState(null);

  const getInfo = async () => {
    const res = await reqToken.get(`access/${id}`);
    setData(res.data);
  };

  useEffect(() => {
    if (getData) {
      getInfo();
    }
    //eslint-disable-next-line
  }, [getData]);

  return (
    <div
      className="modal fade"
      id={`access-modal-${id}`}
      tabIndex="-1"
      aria-labelledby={`access-modal-${id}-label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`access-modal-${id}-label`}>
              Información del acceso
            </h5>
            <button
              type="button"
              className="btn-close-modal btn text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          </div>
          <div className="modal-body">
            {!data && (
              <div className="d-flex justify-content-center align-items-center py-5">
                <div
                  className="spinner-border"
                  role="status"
                  // style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            {data && (
              <ul className="list-group list-group-flush w-100">
                <li className="list-group-item">
                  <div className="row mx-0">
                    <span className="col-4 fw-bold text-start">ID</span>
                    <span className="col-8  text-start">{data.acc_int_id}</span>
                  </div>
                </li>

                <li className="list-group-item bg-light">
                  <div className="row mx-0">
                    <span className="col-4 fw-bold text-start">Nombre</span>
                    <span className="col-8  text-start">
                      {data.acc_text_description}
                    </span>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="row mx-0">
                    <span className="col-4 fw-bold text-start">Etiqueta</span>
                    <span className="col-8  text-start">
                      {data.acc_text_tag}
                    </span>
                  </div>
                </li>

                <li className="list-group-item bg-light">
                  <div className="row mx-0">
                    <span className="col-4 fw-bold text-start">Creado</span>
                    <span className="col-8 text-start">
                      {new Date(data.acc_date_creation_date).toLocaleString()}
                    </span>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="row mx-0">
                    <span className="col-4 fw-bold text-start">Modificado</span>
                    <span className="col-8  text-start">
                      {data.acc_date_modification_date
                        ? new Date(
                            data.acc_date_modification_date
                          ).toLocaleString()
                        : "-"}
                    </span>
                  </div>
                </li>

                <li className="list-group-item bg-light">
                  <div className="row mx-0">
                    <span className="col-4 fw-bold text-start">Eliminado</span>
                    <span className="col-8 text-start">
                      {data.acc_date_deletion_date
                        ? new Date(data.acc_date_deletion_date).toLocaleString()
                        : "-"}
                    </span>
                  </div>
                </li>
              </ul>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
