import React, { useEffect, useState } from "react";
import useRequest from "hooks/useRequest";

import Modal from "./Modal";
import DeleteModal from "./DeleteModal";

import "styles/Contact.css";

export default function GroupsList({
  url,
  relationId,
  height,
  noEdit,
  relationField,
  checkedField,
}) {
  const { reqToken } = useRequest();

  // while loading business info
  const [loading, setLoading] = useState(false);

  // while deleting relation
  const [inProcess, setInProcess] = useState(false);

  // business data
  const [data, setData] = useState([]);

  // get business info
  const getData = async () => {
    setLoading(true);

    const listPromise = await reqToken.get(
      `vwlistgroups${url}/${relationId}?l=999&o=0`
    );

    if (noEdit) {
      setData(
        listPromise.data.datos.filter(
          (item) => item[checkedField] !== "0" && item[checkedField] !== null
        )
      );

      return setLoading(false);
    }

    setData(listPromise.data.datos);

    setLoading(false);
  };

  // change item to not assinged
  const removeItem = (id) => {
    setData(
      data.map((item) =>
        item.gro_int_id === id ? { ...item, groupschecked: null } : item
      )
    );
  };

  // change item to assigned
  const assignItem = (id) => {
    setData(
      data.map((item) =>
        item.gro_int_id === id ? { ...item, groupschecked: id } : item
      )
    );
  };

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="w-100">
      <div className="d-flex flex-column border rounded w-100">
        <p className="w-100 py-2 px-3 text-start bg-secondary rounded-top text-white fw-bold mb-0">
          Grupos asignados
        </p>

        <div className="d-flex flex-column w-100 p-2">
          <Headers />

          <div
            className="business-container"
            style={{ height, maxHeight: height }}
          >
            {loading && (
              <div className="d-flex justify-content-center flex-grow-1 align-items-center">
                <div
                  className="spinner-border"
                  role="status"
                  // style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            {!loading && data.length === 0 && (
              <div className="alert alert-warning text-center py-2">
                No hay grupos relacionados a este usuario
              </div>
            )}

            {!loading &&
              data.length > 0 &&
              data.map((item) => (
                <ListItem
                  key={item.gro_int_id}
                  url={url}
                  id={item.gro_int_id}
                  title={item.gro_text_description}
                  checked={
                    item.groupschecked === "0" || item.groupschecked === null
                      ? false
                      : true
                  }
                  relationId={relationId}
                  removeItem={removeItem}
                  assignItem={assignItem}
                  inProcess={inProcess}
                  setInProcess={setInProcess}
                  noEdit={noEdit}
                  relationField={relationField}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const ListItem = ({
  url,
  id,
  title,
  checked,
  relationId,
  removeItem,
  assignItem,
  inProcess,
  setInProcess,
  noEdit,
  relationField,
}) => {
  const { reqToken } = useRequest();

  const [getData, setGetData] = useState(false);

  const handleAssign = async () => {
    setInProcess(true);

    const res = await reqToken.post(`groups_${url}`, {
      gro_int_id: id,
      [relationField]: relationId,
      que: "c",
    });

    setInProcess(false);

    if (res.status === 200 || res.status === 201) {
      assignItem(id);
    }
  };

  const handleDelete = async () => {
    setInProcess(true);

    const res = await reqToken.post(`groups_${url}`, {
      gro_int_id: id,
      [relationField]: relationId,
      que: "d",
    });

    setInProcess(false);

    if (res.data.status === 204) {
      removeItem(id);
    }
  };

  return (
    <div className="row w-100 mx-0 px-0 border align-items-center">
      <span className="d-none d-lg-block col-lg-2 border-end py-1 text-center">
        {id}
      </span>
      <span className="col-12 col-md-8 col-lg-7 border-end py-1 text-truncate">
        {title}
      </span>
      <span className="d-none d-md-flex col-4 col-md-4 col-lg-3 py-1 gap-2 justify-content-center">
        <button
          onClick={() => setGetData(true)}
          type="button"
          className="btn btn-outline-secondary btn-sm"
          data-bs-toggle="modal"
          data-bs-target={`#groups-modal-${id}`}
          disabled={inProcess ? true : false}
        >
          <i className="far fa-eye"></i>
        </button>

        {!noEdit && !checked && (
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            disabled={inProcess ? true : false}
            onClick={handleAssign}
            title="Crear relación"
          >
            <i className="fas fa-check"></i>
          </button>
        )}

        {!noEdit && checked && (
          <button
            type="button"
            className="btn btn-success btn-sm"
            data-bs-toggle="modal"
            data-bs-target={`#groups-modal-delete-${id}`}
            disabled={inProcess ? true : false}
            title="Eliminar relación"
          >
            <i className="fas fa-check"></i>
          </button>
        )}
      </span>

      <Modal id={id} getData={getData} />
      <DeleteModal id={id} title={title} handleDelete={handleDelete} />
    </div>
  );
};

const Headers = () => {
  return (
    <div className="row w-100 mx-0 px-0 rounded border-bottom mb-2">
      <span className="d-none d-lg-block col-lg-2 fw-bold text-center py-1">
        ID
      </span>
      <span className="col-12 col-md-8 col-lg-7 fw-bold py-1">Grupo</span>
      <span className="d-none d-md-block col-4 col-md-4 col-lg-3 fw-bold text-center py-1">
        Opciones
      </span>
    </div>
  );
};
