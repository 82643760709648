const routeTable = {
  home: "issue",
  customers: "customer",
  users: "/management/users",
  groups: "/management/groups",
  access: "/management/access",
  business: "/management/business",
  topics: "/management/topics",
  members: "/memberships/members",
  types: "/memberships/types",
  payments: "/memberships/payments",
  expenses: "/memberships/expenses",
};

export default routeTable;
