import React from "react";

import { DefaultInput, DefaultTextarea } from "templates/input";
import GroupsList from "components/GroupsList/GroupsList";

export default function AccessForm({
  id,
  handleSubmit,
  handleChange,
  loading,
  error,
  acc_text_description,
  acc_text_tag,
  acc_text_url,
}) {
  return (
    <div className="row w-100 g-3">
      <h3 className="text-center">
        {id !== "0" ? "Editar acceso" : "Nuevo acceso"}
      </h3>
      <form
        onSubmit={handleSubmit}
        className={`${
          id !== "0" ? "col-12 col-lg-6" : "col-12"
        } align-items-center`}
        autoComplete="off"
      >
        <DefaultTextarea
          customClass="col-12"
          label="Descripción"
          placeholder="Ingresa una descripción"
          value={acc_text_description}
          inputName="acc_text_description"
          onChange={handleChange}
          error={error.acc_text_description}
        />

        <DefaultInput
          customClass="col-12"
          label="Etiqueta"
          placeholder="e.j. home, user, admin"
          value={acc_text_tag}
          inputName="acc_text_tag"
          onChange={handleChange}
          error={error.acc_text_tag}
        />

        <DefaultInput
          customClass="col-12"
          label="URL"
          placeholder="e.j. https://www.google.com"
          value={acc_text_url}
          inputName="acc_text_url"
          onChange={handleChange}
          error={error.acc_text_url}
        />

        <div className="col-12 d-flex justify-content-end">
          <button
            type="submit"
            className={`btn btn-${loading ? "secondary" : "primary"}`}
            disabled={loading ? true : false}
          >
            {loading ? (
              <>
                <i className="fas fa-circle-notch fa-spin me-2"></i>
                Guardando...
              </>
            ) : (
              <>
                <i className="fas fa-save me-2"></i>
                Guardar
              </>
            )}
          </button>
        </div>
      </form>

      {id !== "0" && (
        <div className="col-12 col-lg-6">
          <GroupsList
            url="access"
            relationId={id}
            height={285}
            relationField="acc_int_id"
          />
        </div>
      )}
    </div>
  );
}
