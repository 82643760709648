import React from "react";
import { Link } from "react-router-dom";

import "styles/Management.css";

export default function MembershipsMenu() {
  const data = [
    {
      id: 1,
      icon: "fas fa-user-cog me-2",
      title: "Miembros",
      desc: "Gestión de usuarios miembros",
      route: "/memberships/members",
    },
    {
      id: 2,
      icon: "fas fa-layer-group me-2",
      title: "Tipos",
      desc: "Gestión de tipos de membresías",
      route: "/memberships/types",
    },
    {
      id: 3,
      icon: "fas fa-credit-card me-2",
      title: "Pagos",
      desc: "Gestión de pagos de membresías",
      route: "/memberships/payments",
    },
    {
      id: 4,
      icon: "far fa-money-bill-alt me-2",
      title: "Egresos",
      desc: "Gestión de gastos",
      route: "/memberships/expenses",
    },
  ];

  return (
    <div className="w-100 shadow border d-flex flex-column align-items-center justify-content-center rounded py-5 bg-light">
      <h2 className="text-center pb-4">Opciones de membresías</h2>

      <div className="items-container d-flex flex-wrap mx-auto align-items-center justify-content-center gap-3">
        {data.map((item) => (
          <Link
            key={item.id}
            to={item.route}
            className="management-link px-3 py-4 text-decoration-none text-dark border d-flex flex-column align-items-center justify-content-center rounded"
          >
            <h5 className="text-center mb-4 text-decoration-none">
              <i className={item.icon}></i>
              {item.title}
            </h5>
            <p className="text-center w-100 mb-0 text-decoration-none">
              {item.desc}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
}
