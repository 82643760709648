import React from "react";
import { useParams } from "react-router-dom";

import titleSelector from "components/Management/titleSelector";
import ScrollContainer from "components/ScrollContainer";
import moduleTable from "helpers/moduleTable";

export default function ModulePage() {
  const { module } = useParams();

  return (
    <div className="w-100 shadow border d-flex flex-column align-items-center justify-content-center rounded p-3 p-xl-5 bg-light">
      <h2 className="text-center pb-4">{titleSelector[module]}</h2>

      <ScrollContainer
        url={moduleTable(module)}
        headers={<Headers />}
        headersMembers={<HeadersMembers />}
        type={module}
        formDistance="4"
      />
    </div>
  );
}

const Headers = () => {
  return (
    <div className="row w-100 mx-0 px-0 bg-secondary rounded mb-3">
      <span className="col-3 col-md-1 fw-bold border-end py-2 text-center text-white">
        ID
      </span>
      <span className="col-9 col-md-9 fw-bold border-end py-2 text-white">
        Nombre
      </span>
      <span className="d-none d-md-block col-4 col-md-2 fw-bold text-center py-2 text-white">
        Opciones
      </span>
    </div>
  );
};

const HeadersMembers = () => {
  return (
    <div className="row w-100 mx-0 px-0 bg-secondary rounded mb-3">
      <span className="col-3 col-md-1 fw-bold border-end py-2 text-center text-white">
        ID
      </span>
      <span className="col-9 col-md-5 fw-bold border-end py-2 text-white">
        Nombre
      </span>
      <span className="d-none d-md-block col-md-2 fw-bold border-end py-2 text-white text-center">
        A pagar
      </span>
      <span className="d-none d-md-block col-md-2 fw-bold border-end py-2 text-white text-center">
        Total
      </span>
      <span className="d-none d-md-block col-md-2 fw-bold text-center py-2 text-white">
        Opciones
      </span>
    </div>
  );
};
