import React from "react";
import TextEditor from "components/TextEditor";
import { DefaultInput } from "templates/input";
import ScrollContainer from "components/ScrollContainer";
import getDateString from "helpers/dateString";
import parse from "html-react-parser";

export default function Tracking({
  tab,
  id,
  issue,
  topics,
  fieldsInfo,
  setFieldsInfo,
}) {
  const defaultTopic = () => {
    const topic = topics.find((item) =>
      item.top_int_id === issue.issue_data.top_int_id ? item.top_text_name : ""
    );
    return topic?.top_text_name;
  };

  return (
    <div className={`w-100 flex-wrap gap-3 ${tab === 1 ? "d-flex" : "d-none"}`}>
      <div className="d-flex flex-column w-100">
        <p className="word-break">
          <span className="fw-bold">Tema: </span>
          {defaultTopic() || 'Tema no encontrado'}
        </p>
        <p className="word-break mb-0">
          <span className="fw-bold">Descripción de la última actividad: </span>
          {parse(issue.issue_data.isu_text_description)}
        </p>
      </div>

      <div className="d-flex flex-column border rounded w-100">
        <p className="w-100 py-2 px-3 text-start bg-success rounded-top text-white fw-bold mb-0">
          Próxima cita
        </p>
        <div className="d-flex w-100 p-2 gap-2 align-items-end">
          <DefaultInput
            customClass="w-50"
            label="Último contacto"
            type="date"
            mb="0"
            value={issue.firstFollowData.fol_date_creation_date?.split(" ")[0]}
            readOnly
          />

          <DefaultInput
            customClass="w-50"
            label="Próximo contacto"
            type="date"
            mb="0"
            value={fieldsInfo?.next_date_string}
            onChange={({ target }) =>
              setFieldsInfo({
                ...fieldsInfo,
                next_date_string: target.value,
                next_date_number: target.value
                  .split("-")
                  .map((item) => Number(item)),
              })
            }
          />
        </div>

        <p className="w-100 my-2 px-2 text-center">
          <span className="fw-bold me-2">Próxima cita fijada en:</span>
          {fieldsInfo?.next_date_number
            ? getDateString(
                new Date(
                  fieldsInfo.next_date_number[0],
                  fieldsInfo.next_date_number[1],
                  fieldsInfo.next_date_number[2]
                )
              )
            : "Sin fecha fijada"}
        </p>
      </div>

      <div className="d-flex flex-column border rounded w-100">
        <p className="w-100 py-2 px-3 text-start bg-warning rounded-top text-dark fw-bold mb-0">
          Compromiso actual
        </p>
        <div className="w-100 p-2">
          <TextEditor
            height="300"
            onChange={(value) =>
              setFieldsInfo((prevData) => ({
                ...prevData,
                commitment: value,
              }))
            }
            defaultContent={issue.firstFollowData.fol_text_description ?? ""}
          />
        </div>
      </div>

      <div className="d-flex flex-column border rounded w-100">
        <p className="w-100 py-2 px-3 text-start bg-dark rounded-top text-white fw-bold mb-0">
          Archivos adjuntos
        </p>
        <div className="w-100 p-2 pt-3">
          <ScrollContainer
            url={`administration/files_issues/${id}`}
            deleteUrl="files_issues"
            uploadUrl="files_issues"
            headers={<Headers />}
            type="issue_file"
            noAdd
            upload
            formDistance="4"
            id={id}
          />
        </div>
      </div>
    </div>
  );
}

const Headers = () => {
  return (
    <div className="row w-100 mx-0 px-0 bg-secondary rounded mb-3">
      <span className="col-3 col-md-1 fw-bold border-end py-2 text-center text-white">
        ID
      </span>
      <span className="col-9 col-md-9 fw-bold border-end py-2 text-white">
        Archivo
      </span>
      <span className="d-none d-md-block col-4 col-md-2 fw-bold text-center py-2 text-white">
        Opciones
      </span>
    </div>
  );
};
