import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import moduleTable from "helpers/moduleTable";
import useRequest from "hooks/useRequest";

import UsersForm from "components/Forms/UsersForm";
import GroupsForm from "components/Forms/GroupsForm";
import AccessForm from "components/Forms/AccessForm";
import BusinessForm from "components/Forms/BusinessForm";
import TopicsForm from "components/Forms/TopicsForm";
import MemberForm from "components/Forms/MemberForm";
import TypesForm from "components/Forms/TypesForm";
import PaymentsForm from "components/Forms/PaymentsForm";
import ExpensesForm from "components/Forms/ExpensesForm";

export default function ModuleFormPage() {
  const { module, id, userId } = useParams();
  const { reqTokenBase, reqToken, reqTokenFormDataBase } = useRequest();
  const history = useHistory();

  const [newUserId, setNewUserId] = useState(userId ?? "");

  const [reqStatus, setReqStatus] = useState(null);
  const [data, setData] = useState(null);
  const [requestLoading, setReqLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [error, setError] = useState({});

  const getData = async () => {
    setReqLoading(true);

    const res = await reqTokenBase.get(`${moduleTable(module)}/${id}`);
    setData(res.data);

    setReqLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError({});
    setFormLoading(true);

    try {
      if (id !== "0") {
        if (module === "payments") {
          const res = await reqTokenBase.put(
            `${moduleTable(module)}/${id}`,
            data
          );

          if (data.file) {
            const formData = new FormData();
            formData.append("pay_int_id", res.data.id);
            formData.append("file", data.file);
            formData.append("filename", data.filename);

            await reqTokenFormDataBase.post(
              `memberships/files_payments`,
              formData
            );
          }

          history.goBack();
          return;
        }

        await reqTokenBase.put(`${moduleTable(module)}/${id}`, data);
      }

      if (id === "0") {
        if (module === "members") {
          let res;

          if (!newUserId) {
            res = await reqToken.post("users", {
              ...data.info,
              use_int_admin: "0",
              use_text_pass: "nuevousuario",
            });
            setNewUserId(res.data.int);
          }

          await reqTokenBase.post(moduleTable(module), {
            ...data.info,
            use_int_id: res?.data.int || newUserId,
          });

          history.goBack();

          return;
        }

        if (module === "payments") {
          const res = await reqTokenBase.post(moduleTable(module), data);

          if (data.file) {
            const formData = new FormData();
            formData.append("pay_int_id", res.data.id);
            formData.append("file", data.file);
            formData.append("filename", data.filename);

            await reqTokenFormDataBase.post(
              `memberships/files_payments`,
              formData
            );
          }

          history.goBack();
          return;
        }

        await reqTokenBase.post(moduleTable(module), data);
      }

      history.goBack();
    } catch (error) {
      console.log(error);

      if (error.response) {
        setError(error.response?.data.messages);
        setFormLoading(false);
        return;
      }

      setReqStatus(false);
      setFormLoading(false);
    }
  };

  const handleChange = ({ target }) => {
    setData({ ...data, [target.name]: target.value });
  };

  useEffect(() => {
    if (id !== "0") {
      return getData();
    }

    setReqLoading(false);
    //eslint-disable-next-line
  }, []);

  if (requestLoading) {
    return (
      <div className="py-5 d-flex align-items-center justify-content-center w-100 border bg-light shadow">
        <div className="spinner-border text-dark" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="w-100 shadow border d-flex flex-column align-items-center justify-content-center rounded p-3 p-xl-5 bg-light">
      {reqStatus === false && (
        <div className="alert alert-danger border text-center w-100 p-3">
          Un error ha ocurrido, inténtalo más tarde
        </div>
      )}

      {module === "users" && (
        <UsersForm
          id={id}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          setData={setData}
          loading={formLoading}
          error={error}
          {...data}
        />
      )}

      {module === "groups" && (
        <GroupsForm
          id={id}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          loading={formLoading}
          error={error}
          {...data}
        />
      )}

      {module === "access" && (
        <AccessForm
          id={id}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          loading={formLoading}
          error={error}
          {...data}
        />
      )}

      {module === "business" && (
        <BusinessForm
          id={id}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          loading={formLoading}
          error={error}
          {...data}
        />
      )}

      {module === "topics" && (
        <TopicsForm
          id={id}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          loading={formLoading}
          error={error}
          {...data}
        />
      )}

      {module === "members" && (
        <MemberForm
          id={id}
          setData={setData}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          loading={formLoading}
          error={error}
          info={data?.info}
          pagos={data?.pagos}
          membresias={data?.membresias}
        />
      )}

      {module === "types" && (
        <TypesForm
          id={id}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          loading={formLoading}
          error={error}
          {...data}
        />
      )}

      {module === "payments" && (
        <PaymentsForm
          id={id}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          loading={formLoading}
          error={error}
          setData={setData}
          {...data}
        />
      )}

      {module === "expenses" && (
        <ExpensesForm
          id={id}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          loading={formLoading}
          error={error}
          setData={setData}
          {...data}
        />
      )}
    </div>
  );
}
