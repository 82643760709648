import React from "react";
import SunEditor from "../../../node_modules/suneditor-react";

import lang from "../../../node_modules/suneditor/src/lang";
// import { es } from "suneditor/src/lang";

import "../../../node_modules/suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

export default function TextEditor({ onChange, defaultContent, height }) {
  //prettier-ignore
  const options = {
    lang: lang.es,
    imageFileInput: false,
    font: ["Arial", "Comic Sans MS", "Impact", "Georgia", "tahoma", "Trebuchet MS", "Verdana", "Sans-serif"],
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle", "blockquote"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor", "textStyle"],
      ["removeFormat"],
      "/", // Line break
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      ["table", "link", "image"],
      ["fullScreen", "showBlocks"],
      ["preview"],
      ["%768",[
          ["undo", "redo"],
          [":p-More Paragraph-default.more_paragraph","font","fontSize", "formatBlock","paragraphStyle", "blockquote",   ], 
          [":t-More Text-default.more_text",  "bold",  "underline",  "italic",  "strike",  "subscript",  "superscript",  "fontColor",  "hiliteColor",  "textStyle",  "removeFormat", ],
          [":e-More Line-default.more_horizontal",  "outdent",  "indent",  "align",  "horizontalRule",  "list",  "lineHeight", ],
          [":r-More Rich-default.more_plus",  "table",  "link",  "image",  "fullScreen",  "showBlocks",],
        ],
      ],
      ["%480", [
          ["undo", "redo"],
          [":p-More Paragraph-default.more_paragraph","font","fontSize", "formatBlock","paragraphStyle", "blockquote",   ], 
          [":t-More Text-default.more_text",  "bold",  "underline",  "italic",  "strike",  "subscript",  "superscript",  "fontColor",  "hiliteColor",  "textStyle",  "removeFormat", ],
          [":e-More Line-default.more_horizontal",  "outdent",  "indent",  "align",  "horizontalRule",  "list",  "lineHeight", ],
          [":r-More Rich-default.more_plus",  "table",  "link",  "image",  "fullScreen",  "showBlocks",],
        ],
      ],
    ],
  };

  return (
    <SunEditor
      lang="es"
      onChange={onChange}
      setContents={defaultContent}
      setOptions={options}
      placeholder="Escribe aquí..."
      setDefaultStyle="font-family: arial; font-size: 18px;"
      width="100%"
      height={height ? height : "150"}
    />
  );
}
